export default [
  'created',
  'updated',
  'started',
  'stored',
  'retrieved',
  'approved',
  'unapproved',
  'start cancelled',
  'retrieve cancelled',
  'edit cancelled',
]
