export default [
  {
    id: 1,
    name: 'Balfour Beatty Depot',
    client_id: 1,
    client_name: 'Balfour Beatty',
    description:
      'Balfour Beatty plc is a multinational infrastructure group based in the United Kingdom with capabilities in construction services, support services and infrastructure investments.',
    users: [1, 2, 3],
    address_line1: '5 Churchill Place',
    address_line2: 'Canary Wharf',
    city: 'London',
    county: '',
    post_code: 'E14 5HU',
  },
  {
    id: 2,
    name: 'CCFL KS4',
    client_id: 2,
    client_name: 'London Borough of Camden (Schools)',
    description:
      'The London Borough of Camden is a borough in Northwest London, and historically a part of Middlesex.',
    users: [1, 2, 3],
    address_line1: 'Camden Reception',
    address_line2: '5 Pancras Square',
    city: 'London',
    county: '',
    post_code: 'N1C 4AG',
  },
]
