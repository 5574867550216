import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'

export default ({ timestamp }) => {
  const [timerId, setTimerId] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(
    moment(timestamp, 'X').fromNow()
  )

  useEffect(() => {
    clearInterval(timerId)

    setTimerId(
      setInterval(() => {
        setLastUpdated(moment(timestamp, 'X').fromNow())
      }, 60000)
    )

    return () => {
      clearInterval(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp])

  return (
    <Tooltip title={moment(timestamp, 'X').format('DD MMM YYYY, HH:mm')}>
      Last updated {lastUpdated}
    </Tooltip>
  )
}
