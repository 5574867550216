import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { effects as authEffects } from './auth'
import { authify, capitaliseFirstLetter } from '../../utils'

const namespace = 'assessment'

export const effects = {
  approveAssessment: createAsyncThunk(
    'approveAssessment',
    async (id, thunkAPI) =>
      authify(thunkAPI, 'post', `/assessments/${id}/approve`)
  ),
  createAssessment: createAsyncThunk(
    'createAssessment',
    async (data, thunkAPI) => authify(thunkAPI, 'post', '/assessments', data)
  ),
  deleteAssessment: createAsyncThunk('deleteAssessment', async (id, thunkAPI) =>
    authify(thunkAPI, 'delete', `/assessments/${id}`)
  ),
  fetchAssessments: createAsyncThunk('fetchAssessments', async (_, thunkAPI) =>
    authify(thunkAPI, 'get', '/assessments')
  ),
  fetchSettings: createAsyncThunk('fetchSettings', async (id, thunkAPI) =>
    authify(thunkAPI, 'get', `/assessments/${id}`)
  ),
  fetchSitesCompanies: createAsyncThunk(
    'fetchSitesCompanies',
    async (id, thunkAPI) => authify(thunkAPI, 'get', `/sites/${id}/companies`)
  ),
  fetchSitesUsers: createAsyncThunk('fetchSitesUsers', async (id, thunkAPI) =>
    authify(thunkAPI, 'get', `/sites/${id}/users`)
  ),
  rewindAssessment: createAsyncThunk('rewindAssessment', async (id, thunkAPI) =>
    authify(thunkAPI, 'get', `/assessments/${id}/rewind`)
  ),
  saveSettings: createAsyncThunk(
    'saveSettings',
    async ({ id, data }, thunkAPI) =>
      authify(thunkAPI, 'patch', `/assessments/${id}`, data)
  ),
  unapproveAssessment: createAsyncThunk(
    'unapproveAssessment',
    async (id, thunkAPI) =>
      authify(thunkAPI, 'get', `/assessments/${id}/unapprove`)
  ),
}

const initialState = {
  error: {
    approving: null,
    creating: null,
    creatingMessages: null,
    deleting: null,
    fetching: null,
    rewinding: null,
    unapproving: null,
  },
  is: {
    approving: false,
    approvingId: null,
    creating: false,
    deleting: false,
    deletingId: null,
    fetching: false,
    rewinding: false,
    rewindingId: null,
    unapproving: false,
    unapprovingId: null,
  },
  success: {
    approving: false,
    creating: false,
    deleting: false,
    fetching: false,
    rewinding: false,
    unapproving: false,
  },
  lastUpdated: null,
  list: [],
  settingsForm: {
    error: {
      fetching: null,
      saving: null,
      savingMessages: null,
    },
    is: {
      fetching: false,
      saving: false,
    },
    success: {
      fetching: false,
      saving: false,
    },
    values: {},
  },
  sitesCompanies: {
    id: null,
    error: {
      fetching: null,
    },
    is: {
      fetching: false,
    },
    list: [],
  },
  sitesUsers: {
    id: null,
    error: {
      fetching: null,
    },
    is: {
      fetching: false,
    },
    list: [],
  },
}

export const nextReviewPeriods = [
  '1 year',
  '2 years',
  '3 years',
  '4 years',
  '5 years',
]

/* Reducer shortcuts */

const fetchListStart = (name) => (state) => {
  state[name].is.fetching = true
  state[name].error.fetching = initialState[name].error.fetching
  state[name].id = initialState[name].id
  state[name].list = initialState[name].list
}
const fetchListFailure =
  (name) =>
  (state, { payload }) => {
    state[name].is.fetching = initialState[name].is.fetching
    state[name].error.fetching = payload
  }
const fetchListSuccess =
  (name) =>
  (state, { meta, payload }) => {
    state[name].is.fetching = initialState[name].is.fetching
    state[name].error.fetching = initialState[name].error.fetching
    state[name].id = meta.arg
    state[name].list = payload
  }
const fetchListReducers = (name) => {
  const cName = capitaliseFirstLetter(name)
  return {
    [effects[`fetch${cName}`].pending]: fetchListStart(name),
    [effects[`fetch${cName}`].rejected]: fetchListFailure(name),
    [effects[`fetch${cName}`].fulfilled]: fetchListSuccess(name),
  }
}

export const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setSitesCompaniesIsFetching: (state, { payload }) => {
      state.sitesCompanies.is.fetching = payload
    },
    setSitesUsersIsFetching: (state, { payload }) => {
      state.sitesUsers.is.fetching = payload
    },
    reset: () => initialState,
    resetErrorApproving: (state) => {
      state.error.approving = initialState.error.approving
    },
    resetErrorCreating: (state) => {
      state.error.creating = initialState.error.creating
    },
    resetErrorCreatingMessages: (state) => {
      state.error.creatingMessages = initialState.error.creatingMessages
    },
    resetErrorDeleting: (state) => {
      state.error.deleting = initialState.error.deleting
    },
    resetErrorFetching: (state) => {
      state.error.fetching = initialState.error.fetching
    },
    resetErrorRewinding: (state) => {
      state.error.rewinding = initialState.error.rewinding
    },
    resetErrorUnapproving: (state) => {
      state.error.unapproving = initialState.error.unapproving
    },
    resetSettingsFormValues: (state) => {
      state.settingsForm.values = initialState.settingsForm.values
    },
    resetSettingsFormErrorSaving: (state) => {
      state.settingsForm.error.saving = initialState.settingsForm.error.saving
    },
    resetSettingsFormErrorSavingMessages: (state) => {
      state.settingsForm.error.savingMessages =
        initialState.settingsForm.error.savingMessages
    },
    resetSettingsFormSuccessSaving: (state) => {
      state.settingsForm.success.saving =
        initialState.settingsForm.success.saving
    },
    resetSettingsFormSuccessFetching: (state) => {
      state.settingsForm.success.fetching =
        initialState.settingsForm.success.fetching
    },
    resetSuccessApproving: (state) => {
      state.success.approving = initialState.success.approving
    },
    resetSuccessCreating: (state) => {
      state.success.creating = initialState.success.creating
    },
    resetSuccessDeleting: (state) => {
      state.success.deleting = initialState.success.deleting
    },
    resetSuccessFetching: (state) => {
      state.success.fetching = initialState.success.fetching
    },
    resetSuccessRewinding: (state) => {
      state.success.rewinding = initialState.success.rewinding
    },
    resetSuccessUnapproving: (state) => {
      state.success.unapproving = initialState.success.unapproving
    },
  },
  extraReducers: {
    [effects.approveAssessment.pending]: (state, { meta: { arg } }) => {
      state.is.approving = true
      state.is.approvingId = arg
      state.error.approving = initialState.error.approving
      state.success.approving = initialState.success.approving
    },
    [effects.approveAssessment.rejected]: (state) => {
      state.is.approving = initialState.is.approving
      state.is.approvingId = initialState.is.approvingId
      state.error.approving = true
      state.success.approving = initialState.success.approving
    },
    [effects.approveAssessment.fulfilled]: (state) => {
      state.is.approving = initialState.is.approving
      state.is.approvingId = initialState.is.approvingId
      state.error.approving = initialState.error.approving
      state.success.approving = true
    },
    [effects.createAssessment.pending]: (state) => {
      state.is.creating = true
      state.error.creating = initialState.error.creating
      state.success.creating = initialState.success.creating
    },
    [effects.createAssessment.rejected]: (state, { payload }) => {
      state.is.creating = initialState.is.creating
      state.error.creating = true
      state.error.creatingMessages = payload
      state.success.creating = initialState.success.creating
    },
    [effects.createAssessment.fulfilled]: (state) => {
      state.is.creating = initialState.is.creating
      state.error.creating = initialState.error.creating
      state.success.creating = true
    },
    [effects.deleteAssessment.pending]: (state, { meta: { arg } }) => {
      state.is.deleting = true
      state.is.deletingId = arg
      state.error.deleting = initialState.error.deleting
      state.success.deleting = initialState.success.deleting
    },
    [effects.deleteAssessment.rejected]: (state, { payload }) => {
      state.is.deleting = initialState.is.deleting
      state.is.deletingId = initialState.is.deletingId
      state.error.deleting = payload
      state.success.deleting = initialState.success.deleting
    },
    [effects.deleteAssessment.fulfilled]: (state) => {
      state.is.deleting = initialState.is.deleting
      state.is.deletingId = initialState.is.deletingId
      state.error.deleting = initialState.error.deleting
      state.success.deleting = true
    },
    [effects.fetchAssessments.pending]: (state) => {
      state.is.fetching = true
      state.error.fetching = initialState.error.fetching
      state.success.fetching = initialState.error.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchAssessments.rejected]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = payload
      state.success.fetching = initialState.success.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchAssessments.fulfilled]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = initialState.error.fetching
      state.success.fetching = true
      state.list = payload || []
      state.lastUpdated = moment().format('X')
    },
    [effects.fetchSettings.pending]: (state) => {
      state.settingsForm.is.fetching = true
      state.settingsForm.error.fetching =
        initialState.settingsForm.error.fetching
      state.settingsForm.success.fetching =
        initialState.settingsForm.success.fetching
    },
    [effects.fetchSettings.rejected]: (state, { payload }) => {
      state.settingsForm.is.fetching = initialState.settingsForm.is.fetching
      state.settingsForm.error.fetching = payload
      state.settingsForm.success.fetching =
        initialState.settingsForm.success.fetching
    },
    [effects.fetchSettings.fulfilled]: (state, { payload }) => {
      state.settingsForm.is.fetching = initialState.settingsForm.is.fetching
      state.settingsForm.error.fetching =
        initialState.settingsForm.error.fetching
      state.settingsForm.success.fetching = true
      state.settingsForm.values = payload[0]
    },
    [effects.rewindAssessment.pending]: (state, { meta: { arg } }) => {
      state.is.rewinding = true
      state.is.rewindingId = arg
      state.error.rewinding = initialState.error.rewinding
      state.success.rewinding = initialState.success.rewinding
    },
    [effects.rewindAssessment.rejected]: (state) => {
      state.is.rewinding = initialState.is.rewinding
      state.is.rewindingId = initialState.is.rewindingId
      state.error.rewinding = true
      state.success.rewinding = initialState.success.rewinding
    },
    [effects.rewindAssessment.fulfilled]: (state) => {
      state.is.rewinding = initialState.is.rewinding
      state.is.rewindingId = initialState.is.rewindingId
      state.error.rewinding = initialState.error.rewinding
      state.success.rewinding = true
    },
    [effects.saveSettings.pending]: (state) => {
      state.settingsForm.is.saving = true
      state.settingsForm.error.saving = initialState.settingsForm.error.saving
      state.settingsForm.success.saving =
        initialState.settingsForm.success.saving
    },
    [effects.saveSettings.rejected]: (state, { payload }) => {
      state.settingsForm.is.saving = initialState.settingsForm.is.saving
      state.settingsForm.error.saving = true
      state.settingsForm.error.savingMessages = payload
      state.settingsForm.success.saving =
        initialState.settingsForm.success.saving
    },
    [effects.saveSettings.fulfilled]: (state) => {
      state.settingsForm.is.saving = initialState.settingsForm.is.saving
      state.settingsForm.error.saving = initialState.settingsForm.error.saving
      state.settingsForm.success.saving = true
    },
    [effects.unapproveAssessment.pending]: (state, { meta: { arg } }) => {
      state.is.unapproving = true
      state.is.unapprovingId = arg
      state.error.unapproving = initialState.error.unapproving
      state.success.unapproving = initialState.success.unapproving
    },
    [effects.unapproveAssessment.rejected]: (state) => {
      state.is.unapproving = initialState.is.unapproving
      state.is.unapprovingId = initialState.is.unapprovingId
      state.error.unapproving = true
      state.success.unapproving = initialState.success.unapproving
    },
    [effects.unapproveAssessment.fulfilled]: (state) => {
      state.is.unapproving = initialState.is.unapproving
      state.is.unapprovingId = initialState.is.unapprovingId
      state.error.unapproving = initialState.error.unapproving
      state.success.unapproving = true
    },
    ...fetchListReducers('sitesCompanies'),
    ...fetchListReducers('sitesUsers'),
    [authEffects.logout.fulfilled]: () => initialState,
  },
})

export const selectors = {
  state: (state) => state[namespace],
}

const localSelectors = {
  assessments: (state) => state.list,
  settingsFormValues: (state) => state.settingsForm.values,
}

export const reselectors = {
  assessmentsWithLastUpdated: createSelector(
    [localSelectors.assessments],
    (assessments = []) =>
      assessments.map((assessment) => ({
        ...assessment,
        last_updated: moment(
          Math.max(
            ...assessment.activity.map((activity) =>
              moment(activity.activity_at).format('X')
            )
          ),
          'X'
        ).format('YYYY-MM-DD HH:mm:ss'),
      }))
  ),
  getInitialSettingsFormValues: createSelector(
    [localSelectors.settingsFormValues],
    (settingsFormValues) => ({
      ...settingsFormValues,
      ...JSON.parse(settingsFormValues.contacts),
    })
  ),
}

export default reducer
