export default [
  {
    title: 'BOCIOC M LIMITED',
    key: 'BOCIOC M LIMITED',
    value: 'BOCIOC M LIMITED',
    children: [
      {
        title: 'Devan Stuteley',
        key: 'BOCIOC M LIMITED | Devan Stuteley',
        value: 'BOCIOC M LIMITED | Devan Stuteley',
        id: 2,
      },
    ],
  },
  {
    title: 'COMPUTERS LTD',
    key: 'COMPUTERS LTD',
    value: 'COMPUTERS LTD',
    children: [
      {
        title: 'Devan Stuteley',
        key: 'COMPUTERS LTD | Devan Stuteley',
        value: 'COMPUTERS LTD | Devan Stuteley',
        id: 2,
      },
    ],
  },
  {
    title: 'GRAY CARPENTRY LTD',
    key: 'GRAY CARPENTRY LTD',
    value: 'GRAY CARPENTRY LTD',
    children: [
      {
        title: 'Devan Stuteley',
        key: 'GRAY CARPENTRY LTD | Devan Stuteley',
        value: 'GRAY CARPENTRY LTD | Devan Stuteley',
        id: 2,
      },
    ],
  },
  {
    title: 'London Borough of Camden (Schools)',
    key: 'London Borough of Camden (Schools)',
    value: 'London Borough of Camden (Schools)',
    children: [
      {
        title: 'Devan Stuteley',
        key: 'London Borough of Camden (Schools) | Devan Stuteley',
        value: 'London Borough of Camden (Schools) | Devan Stuteley',
        id: 2,
      },
    ],
  },
  {
    title: 'MARINE AND GENERAL MUTUAL LIFE ASSURANCE SOCIETY',
    key: 'MARINE AND GENERAL MUTUAL LIFE ASSURANCE SOCIETY',
    value: 'MARINE AND GENERAL MUTUAL LIFE ASSURANCE SOCIETY',
    children: [
      {
        title: 'Devan Stuteley',
        key:
          'MARINE AND GENERAL MUTUAL LIFE ASSURANCE SOCIETY | Devan Stuteley',
        value:
          'MARINE AND GENERAL MUTUAL LIFE ASSURANCE SOCIETY | Devan Stuteley',
        id: 2,
      },
    ],
  },
  {
    title: 'RSK GROUP LIMITED',
    key: 'RSK GROUP LIMITED',
    value: 'RSK GROUP LIMITED',
    children: [
      {
        title: 'Devan Stuteley',
        key: 'RSK GROUP LIMITED | Devan Stuteley',
        value: 'RSK GROUP LIMITED | Devan Stuteley',
        id: 2,
      },
    ],
  },
  {
    title: 'Remote Tech Ltd',
    key: 'Remote Tech Ltd',
    value: 'Remote Tech Ltd',
    children: [
      {
        title: 'Admin User',
        key: 'Remote Tech Ltd | Admin User',
        value: 'Remote Tech Ltd | Admin User',
        id: 5,
      },
      {
        title: 'Verified User',
        key: 'Remote Tech Ltd | Verified User',
        value: 'Remote Tech Ltd | Verified User',
        id: 6,
      },
      {
        title: 'Jon Green',
        key: 'Remote Tech Ltd | Jon Green',
        value: 'Remote Tech Ltd | Jon Green',
        id: 7,
      },
      {
        title: 'Devan Stuteley',
        key: 'Remote Tech Ltd | Devan Stuteley',
        value: 'Remote Tech Ltd | Devan Stuteley',
        id: 2,
      },
      {
        title: 'Joe Finn',
        key: 'Remote Tech Ltd | Joe Finn',
        value: 'Remote Tech Ltd | Joe Finn',
        id: 3,
      },
      {
        title: 'Aaron Wallace',
        key: 'Remote Tech Ltd | Aaron Wallace',
        value: 'Remote Tech Ltd | Aaron Wallace',
        id: 1,
      },
    ],
  },
  {
    title: 'Safewater IO Ltd',
    key: 'Safewater IO Ltd',
    value: 'Safewater IO Ltd',
    children: [
      {
        title: 'Devan Stuteley',
        key: 'Safewater IO Ltd | Devan Stuteley',
        value: 'Safewater IO Ltd | Devan Stuteley',
        id: 2,
      },
      {
        title: 'Joe Finn',
        key: 'Safewater IO Ltd | Joe Finn',
        value: 'Safewater IO Ltd | Joe Finn',
        id: 3,
      },
      {
        title: 'Aaron Wallace',
        key: 'Safewater IO Ltd | Aaron Wallace',
        value: 'Safewater IO Ltd | Aaron Wallace',
        id: 1,
      },
    ],
  },
]
