import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Spin,
  TreeSelect,
  Typography,
} from 'antd'
import { CloudUploadOutlined, SyncOutlined } from '@ant-design/icons'
import { Option, Select } from '../components'
import { selectors as authSelectors } from '../store/modules/auth'
import {
  effects as organisationEffects,
  selectors as organisationSelectors,
} from '../store/modules/organisation'
import {
  actions as siteActions,
  effects as siteEffects,
  selectors as siteSelectors,
} from '../store/modules/site'
import {
  effects as orgUserEffects,
  selectors as orgUserSelectors,
  reselectors as orgUserReselectors,
} from '../store/modules/orgUser'
import { accessTokenValidAndNotExpired } from '../utils/authify'
import './index.scss'

const { Title } = Typography

export default ({
  editSiteData,
  setEditSiteData,
  modalVisible,
  setModalVisible,
}) => {
  const authState = useSelector(authSelectors.state)
  const siteState = useSelector(siteSelectors.state)
  const orgUserState = useSelector(orgUserSelectors.state)
  const organisationState = useSelector(organisationSelectors.state)

  const { accessToken } = authState
  const organisationsList = organisationState?.list || []
  const orgUsersList = orgUserState?.list || []

  const userList = orgUserReselectors.userList(orgUserState)

  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const closeModal = () => setModalVisible(false)

  useEffect(() => {
    if (accessTokenValidAndNotExpired(accessToken)) {
      if (organisationsList.length === 0) {
        dispatch(organisationEffects.fetchOrganisations())
      }
      if (orgUsersList.length === 0) {
        dispatch(orgUserEffects.fetchOrgUsers())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  useEffect(() => {
    if (editSiteData) {
      form.setFieldsValue(editSiteData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSiteData])

  useEffect(() => {
    if (siteState.success.creating || siteState.success.saving) {
      notification.success({
        message: `Site ${editSiteData ? 'Saved' : 'Created'}`,
      })
      dispatch(
        siteActions[`resetSuccess${editSiteData ? 'Saving' : 'Creating'}`]()
      )
      closeModal()
    } else if (siteState.error.creating || siteState.error.saving) {
      notification.error({
        message: `Could not ${editSiteData ? 'save' : 'create'} Site`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    siteState.success.creating,
    siteState.success.saving,
    siteState.error.creating,
    siteState.error.saving,
  ])

  const onFinish = (data) => {
    if (editSiteData) {
      dispatch(
        siteEffects.saveSite({
          siteId: editSiteData?.id,
          data: {
            ...data,
            client_id: organisationsList.find(
              (org) => org.name === data.client_name
            ).id,
          },
        })
      )
    } else {
      const orgId = organisationsList.find(
        (org) => org.name === data.client_name
      ).id
      dispatch(
        siteEffects.createSite({
          orgId,
          data,
        })
      )
    }
  }

  const getFormItemHelp = (name) => {
    if (siteState.error.creating?.errors) {
      return siteState.error.creating.errors[name]
    } else if (siteState.error.saving?.errors) {
      return siteState.error.saving.errors[name]
    }
  }

  const getFormItemValidateStatus = (name) => {
    if (
      (siteState.error.creating?.errors &&
        siteState.error.creating?.errors[name]) ||
      (siteState.error.saving?.errors && siteState.error.saving?.errors[name])
    ) {
      return 'error'
    }
  }

  return (
    <Modal
      afterClose={() => {
        form.resetFields()
        dispatch(
          siteActions[`resetError${editSiteData ? 'Saving' : 'Creating'}`]()
        )
        setEditSiteData(null)
      }}
      className="site-form-modal"
      footer={null}
      onCancel={closeModal}
      visible={modalVisible}
    >
      <Spin
        size="large"
        spinning={siteState.is[editSiteData ? 'saving' : 'creating']}
        tip={`${editSiteData ? 'Saving' : 'Creating'} Site...`}
      >
        <Form className="site-form" form={form} onFinish={onFinish}>
          <Title level={3}>{editSiteData ? 'Edit' : 'Create a New'} Site</Title>
          <Title level={4}>SITE DETAILS</Title>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Site Name"
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Site Name is required' }]}
                help={getFormItemHelp('name')}
                validateStatus={getFormItemValidateStatus('name')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <Row align="middle" gutter={12}>
                    <Col>Organisation</Col>
                    <Col>
                      <Button
                        disabled={organisationState.is.fetching || editSiteData}
                        icon={<SyncOutlined />}
                        onClick={() =>
                          dispatch(organisationEffects.fetchOrganisations())
                        }
                      />
                    </Col>
                  </Row>
                }
                name="client_name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Organisation is required' },
                ]}
                help={getFormItemHelp('client_name')}
                validateStatus={getFormItemValidateStatus('client_name')}
              >
                <Select
                  disabled={!!editSiteData}
                  loading={organisationState.is.fetching}
                  showSearch
                >
                  {organisationsList.map((organisation) => (
                    <Option value={organisation.name} key={organisation.id}>
                      {organisation.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                extra="Max 200 Characters"
                label="Site Description"
                name="description"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                help={getFormItemHelp('description')}
                validateStatus={getFormItemValidateStatus('description')}
              >
                <Input.TextArea maxLength={200} rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Title level={4}>USERS</Title>
          <Row>
            <Col span={24}>
              <Spin spinning={orgUserState.is.fetching}>
                <Form.Item
                  label={
                    <Row align="middle" gutter={12}>
                      <Col>Grant Site access to</Col>
                      <Col>
                        <Button
                          disabled={orgUserState.is.fetching}
                          icon={<SyncOutlined />}
                          onClick={() =>
                            dispatch(orgUserEffects.fetchOrgUsers())
                          }
                        />
                      </Col>
                    </Row>
                  }
                  name="users"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    { required: true, message: 'User Access is required' },
                  ]}
                  help={getFormItemHelp('users')}
                  validateStatus={getFormItemValidateStatus('users')}
                  getValueProps={(value) => {
                    if (Array.isArray(value)) {
                      return {
                        value: value.map(
                          (v) => userList.find((user) => user.id === v).value
                        ),
                      }
                    }
                  }}
                  normalize={(value) => [
                    ...new Set(
                      value.map(
                        (v) => userList.find((user) => user.value === v).id
                      )
                    ),
                  ]}
                >
                  <TreeSelect
                    treeData={orgUsersList}
                    treeCheckable
                    treeDefaultExpandAll
                  />
                </Form.Item>
              </Spin>
            </Col>
          </Row>
          <Title level={4}>SITE ADDRESS</Title>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Address Line 1"
                name="address_line1"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Address Line 1 is required' },
                ]}
                help={getFormItemHelp('address_line1')}
                validateStatus={getFormItemValidateStatus('address_line1')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Address Line 2"
                name="address_line2"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                help={getFormItemHelp('address_line2')}
                validateStatus={getFormItemValidateStatus('address_line2')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="City"
                name="city"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'City is required' }]}
                help={getFormItemHelp('city')}
                validateStatus={getFormItemValidateStatus('city')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="County"
                name="county"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                help={getFormItemHelp('county')}
                validateStatus={getFormItemValidateStatus('county')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Postcode"
                name="post_code"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Postcode is required' }]}
                help={getFormItemHelp('post_code')}
                validateStatus={getFormItemValidateStatus('post_code')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={24}>
            <Col span={16}>
              {((siteState.error.creating?.message &&
                !siteState.error.creating?.exception) ||
                (siteState.error.saving?.message &&
                  !siteState.error.saving?.exception)) && (
                <Alert
                  message={
                    siteState.error.creating?.message ||
                    siteState.error.saving?.message
                  }
                  type="error"
                  showIcon
                />
              )}
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                htmlType="submit"
              >
                {editSiteData ? 'Save' : 'Create'} Site
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
