import React, { useLayoutEffect } from 'react'
import { Select } from 'antd'
import { fixAutocomplete } from '../utils'

export default (props) => {
  useLayoutEffect(() => {
    fixAutocomplete()
  }, [])

  return <Select {...props}>{props.children}</Select>
}
