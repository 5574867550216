export default [
  {
    id: 1,
    status: 'started',
    site_id: 1,
    site_name: 'Test site 1',
    client_id: 1,
    client_name: 'Test client 1',
    surveyor_id: 1,
    surveyor_name: 'Test surveyor 1',
    project_manager_id: 1,
    project_manager_name: 'Test project manager 1',
    reviewer_id: 1,
    reviewer_name: 'Test reviewer 1',
    activity: [{ activity_at: '2020-06-03 13:18:02' }],
  },
  {
    id: 2,
    status: 'created',
    site_id: 2,
    site_name: 'Test site 2',
    client_id: 2,
    client_name: 'Test client 2',
    surveyor_id: 2,
    surveyor_name: 'Test surveyor 2',
    project_manager_id: 2,
    project_manager_name: 'Test project manager 2',
    reviewer_id: 2,
    reviewer_name: 'Test reviewer 2',
    activity: [{ activity_at: '2020-06-01 15:46:13' }],
  },
  {
    id: 3,
    status: 'editing',
    site_id: 3,
    site_name: 'Test site 3',
    client_id: 3,
    client_name: 'Test client 3',
    surveyor_id: 3,
    surveyor_name: 'Test surveyor 3',
    project_manager_id: 3,
    project_manager_name: 'Test project manager 3',
    reviewer_id: 3,
    reviewer_name: 'Test reviewer 3',
    activity: [{ activity_at: '2020-05-20 09:15:23' }],
  },
  {
    id: 4,
    status: 'approved',
    site_id: 4,
    site_name: 'Test site 4',
    client_id: 4,
    client_name: 'Test client 4',
    surveyor_id: 4,
    surveyor_name: 'Test surveyor 4',
    project_manager_id: 4,
    project_manager_name: 'Test project manager 4',
    reviewer_id: 4,
    reviewer_name: 'Test reviewer 4',
    activity: [{ activity_at: '2020-06-02 12:05:54' }],
  },
  {
    id: 5,
    status: 'completed',
    site_id: 5,
    site_name: 'Test site 5',
    client_id: 5,
    client_name: 'Test client 5',
    surveyor_id: 5,
    surveyor_name: 'Test surveyor 5',
    project_manager_id: 5,
    project_manager_name: 'Test project manager 5',
    reviewer_id: 5,
    reviewer_name: 'Test reviewer 5',
    activity: [{ activity_at: '2020-06-01 22:04:11' }],
  },
  {
    id: 6,
    status: 'draft',
    site_id: 6,
    site_name: 'Test site 6',
    client_id: 6,
    client_name: 'Test client 6',
    surveyor_id: 6,
    surveyor_name: 'Test surveyor 6',
    project_manager_id: 6,
    project_manager_name: 'Test project manager 6',
    reviewer_id: 6,
    reviewer_name: 'Test reviewer 6',
    activity: [{ activity_at: '2020-05-03 15:16:17' }],
  },
]
