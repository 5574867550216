import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { Affix, Avatar, Col, Layout, Menu, Popover, Row } from 'antd'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import * as Pages from './pages'
import { actions, effects, selectors } from './store/modules/auth'
import {
  effects as siteEffects,
  selectors as siteSelectors,
} from './store/modules/site'
import { accessTokenValidAndNotExpired } from './utils/authify'
import './App.scss'

const pageMetaData = [
  {
    label: 'Assessments',
    route: '/assessments',
  },
  {
    label: 'Organisations',
    route: '/organisations',
  },
  {
    label: 'Sites',
    route: '/sites',
  },
  {
    label: 'Contacts',
    route: '/contacts',
  },
]

export default () => {
  const authState = useSelector(selectors.state)
  const siteState = useSelector(siteSelectors.state)

  const { accessToken, firstName, lastName } = authState

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      accessTokenValidAndNotExpired(accessToken) &&
      siteState.list.length === 0
    ) {
      dispatch(siteEffects.fetchSites())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  useEffect(() => {
    if (authState.error.loggingOut) {
      dispatch(actions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.error.loggingOut])

  const defaultRoute = accessToken ? '/assessments' : '/login'

  return (
    <Router>
      <Layout>
        {accessToken && (
          <Layout.Header className="header">
            <Row align="middle" justify="space-between">
              <Col>
                <Link to="/" className="header__home-link">
                  RA Web
                </Link>
              </Col>
              <Col>
                <Popover
                  content={
                    <Menu className="header__user-menu">
                      <Menu.Item
                        icon={<LogoutOutlined />}
                        onClick={() => dispatch(effects.logout(accessToken))}
                      >
                        Logout
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                  trigger="click"
                >
                  <Row className="header__user" gutter={12}>
                    <Col className="header__user-name">
                      {authState.is.loggingOut
                        ? 'Logging out...'
                        : `${firstName} ${lastName}`}
                    </Col>
                    <Col>
                      <Avatar
                        icon={<UserOutlined />}
                        className="header__user-avatar"
                      />
                    </Col>
                  </Row>
                </Popover>
              </Col>
            </Row>
          </Layout.Header>
        )}
        <Layout
          style={{
            marginTop: accessToken ? 64 : 0,
            minHeight: accessToken ? 'calc(100vh - 64px)' : '100vh',
          }}
        >
          {accessToken && (
            <Layout.Sider theme="light" className="sidebar">
              <Affix offsetTop={64}>
                <Menu className="nav" mode="inline" theme="light">
                  {pageMetaData.map((page) => (
                    <Menu.Item key={page.route}>
                      <NavLink to={page.route}>{page.label}</NavLink>
                    </Menu.Item>
                  ))}
                </Menu>
              </Affix>
            </Layout.Sider>
          )}
          <Layout>
            <Layout.Content className="content">
              <Switch>
                <Route exact path="/">
                  <Redirect to={defaultRoute} />
                </Route>
                <Route path="/login">
                  <Pages.Login />
                </Route>
                {pageMetaData.map((page) => [
                  <Route exact path={page.route}>
                    {accessToken ? (
                      <Pages.List title={page.label} />
                    ) : (
                      <Redirect to="/login" />
                    )}
                  </Route>,
                  <Route path={`${page.route}/create`}>
                    {accessToken ? (
                      <Pages.Create
                        listPageRoute={page.route}
                        label={page.label.substring(0, page.label.length - 1)}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )}
                  </Route>,
                  <Route path={`${page.route}/:id/edit`}>
                    {accessToken ? (
                      <Pages.Edit
                        label={page.label.substring(0, page.label.length - 1)}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )}
                  </Route>,
                ])}
              </Switch>
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  )
}
