import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { effects as authEffects } from './auth'
import { authify } from '../../utils'

const namespace = 'orgUser'

export const effects = {
  fetchOrgUsers: createAsyncThunk('fetchOrgUsers', async (_, thunkAPI) =>
    authify(thunkAPI, 'get', '/users')
  ),
}

const initialState = {
  error: {
    fetching: null,
  },
  is: {
    fetching: false,
  },
  success: {
    fetching: false,
  },
  list: [],
}

export const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset: () => initialState,
    resetErrorFetching: (state) => {
      state.error.fetching = initialState.error.fetching
    },
    resetSuccessFetching: (state) => {
      state.success.fetching = initialState.success.fetching
    },
  },
  extraReducers: {
    [effects.fetchOrgUsers.pending]: (state) => {
      state.is.fetching = true
      state.error.fetching = initialState.error.fetching
      state.success.fetching = initialState.error.fetching
    },
    [effects.fetchOrgUsers.rejected]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = payload
      state.success.fetching = initialState.success.fetching
    },
    [effects.fetchOrgUsers.fulfilled]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = initialState.error.fetching
      state.success.fetching = true
      state.list = payload || []
    },
    [authEffects.logout.fulfilled]: () => initialState,
  },
})

export const selectors = {
  state: (state) => state[namespace],
}

const localSelectors = {
  list: (state) => state.list,
}

export const reselectors = {
  userList: createSelector([localSelectors.list], (list) =>
    [].concat.apply(
      [],
      list?.map((org) => org.children)
    )
  ),
}

export default reducer
