import axios from 'axios'
import { assessments, organisations, orgUsers, sites } from './mockData'
import { objectFilter } from '../utils'

const mapToMockData = [
  {
    type: 'get',
    endpoint: '/assessments',
    responseData: assessments,
  },
  {
    type: 'get',
    endpoint: '/organizations',
    responseData: organisations,
  },
  {
    type: 'get',
    endpoint: '/sites',
    responseData: sites,
  },
  {
    type: 'get',
    endpoint: '/users',
    responseData: orgUsers,
  },
]

export default (type, endpoint, requestData, useMockData = false) => {
  if (useMockData) {
    const mockData = mapToMockData.find(
      (m) => m.type === type && m.endpoint === endpoint
    )
    if (!!mockData) {
      return mockData.responseData
    } else {
      throw Error('Could not find mock data')
    }
  } else if (type === 'auth') {
    if (endpoint === 'token') {
      const url = process.env.REACT_APP_UMS_TOKEN_URL

      let grant_type
      if (requestData.code) {
        grant_type = 'authorization_code'
      } else if (requestData.refresh_token) {
        grant_type = 'refresh_token'
      }

      return axios
        .post(url, {
          ...requestData,
          grant_type,
          client_id: process.env.REACT_APP_UMS_CLIENT_ID,
          client_secret: process.env.REACT_APP_UMS_CLIENT_SECRET,
          redirect_uri: process.env.REACT_APP_UMS_REDIRECT_URL,
        })
        .then((response) => {
          console.log(`POST ${url} response`, response)
          if (response.data.error) {
            throw response.data.error
          } else {
            return response.data
          }
        })
        .catch((error) => {
          console.log(`POST ${url} error`, error)
          throw error
        })
    } else if (endpoint === 'me') {
      const url = process.env.REACT_APP_UMS_USER_DETAILS_URL
      return axios
        .get(url, {
          headers: {
            Authorization: requestData.accessToken,
          },
        })
        .then((response) => {
          console.log(`GET ${url} response`, response)
          if (response.data.error) {
            throw response.data.error
          } else {
            return response.data
          }
        })
        .catch((error) => {
          console.log(`GET ${url} error`, error)
          throw error
        })
    } else if (endpoint === 'logout') {
      const url = process.env.REACT_APP_UMS_REVOKE_URL
      const params = {
        client_id: process.env.REACT_APP_UMS_CLIENT_ID,
        client_secret: process.env.REACT_APP_UMS_CLIENT_SECRET,
        token: requestData.accessToken,
      }
      return axios
        .post(url, params)
        .then((response) => {
          console.log(`POST ${url} response`, response)
          return response
        })
        .catch((error) => {
          console.log(`POST ${url} error`, JSON.stringify(error))
          throw error
        })
    }
  } else if (type === 'companieshouse') {
    const url = `${process.env.REACT_APP_COMPANIES_HOUSE_URL}/${endpoint}`
    return axios
      .get(url, {
        headers: {
          Authorization: process.env.REACT_APP_COMPANIES_HOUSE_AUTHORIZATION,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        console.log('companieshouse error', error)
        throw error
      })
  } else {
    const url = `${process.env.REACT_APP_ASSESSMENTS_URL}${endpoint}`

    const headers = { headers: { Authorization: requestData.accessToken } }
    const params = objectFilter(requestData, ([key]) => key !== 'accessToken')

    let axiosRequest
    if (['get', 'delete'].includes(type)) {
      const requestObj = {
        ...params,
        ...headers,
      }
      axiosRequest = () => axios[type](url, requestObj)
      console.log(`${type.toUpperCase()} ${endpoint} request data`, requestObj)
    } else if (['post', 'patch'].includes(type)) {
      axiosRequest = () => axios[type](url, params, headers)
      console.log(`${type.toUpperCase()} ${endpoint} request params`, params)
      console.log(`${type.toUpperCase()} ${endpoint} request headers`, headers)
    }

    return axiosRequest()
      .then((response) => {
        console.log(
          `${type.toUpperCase()} ${endpoint} response data`,
          response.data
        )
        if (!response.data.status) {
          throw response.data.message
        } else {
          return response.data.data
        }
      })
      .catch((error) => {
        const returnError = error.response?.data || error
        console.log(
          `${type.toUpperCase()} ${endpoint} response error`,
          returnError
        )
        throw returnError
      })
  }
}
