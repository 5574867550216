import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Menu,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { ContactForm } from '../../../forms'
import { ListPageHeader } from '../../../components'
import {
  actions as contactActions,
  effects as contactEffects,
  selectors as contactSelectors,
} from '../../../store/modules/contact'

const { Text } = Typography

export default () => {
  const contactState = useSelector(contactSelectors.state)

  const [editContactData, setEditContactData] = useState(null)
  const [filteredInfo, setFilteredInfo] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [searchedColumn, setSearchedColumn] = useState({})
  const [searchText, setSearchText] = useState({})

  const clearAllFilters = () => {
    setFilteredInfo(null)
    setSearchText({})
    setSearchedColumn({})
  }

  const clearFilter = (dataIndex, confirm = () => {}) => {
    setFilteredInfo({ ...filteredInfo, [dataIndex]: null })
    setSearchText({ ...searchText, [dataIndex]: null })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: null })
    confirm()
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (contactState.list.length === 0 && !contactState.success.deleting) {
      dispatch(contactEffects.fetchContacts())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (contactState.success.deleting) {
      notification.success({ message: 'Contact deleted' })
      dispatch(contactEffects.fetchContacts())
      dispatch(contactActions.resetSuccessDeleting())
    } else if (contactState.error.deleting) {
      notification.error({ message: 'Could not delete contact' })
      dispatch(contactActions.resetErrorDeleting())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactState.success.deleting, contactState.error.deleting])

  const getColumnSearchProps = ({ dataIndex, label }) => {
    let searchInput
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node
            }}
            placeholder={`Filter ${label}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilter(dataIndex, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select())
        }
      },
      render: (text) =>
        searchedColumn[dataIndex] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText[dataIndex]]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText({ ...searchText, [dataIndex]: selectedKeys[0] })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: true })
  }

  const editContact = (record) => {
    setEditContactData(record)
    setModalVisible(true)
  }

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      filteredValue: filteredInfo?.name || null,
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
      ...getColumnSearchProps({
        dataIndex: 'name',
        label: 'Name',
      }),
    },
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Title',
      filteredValue: filteredInfo?.title || null,
      sorter: (a, b) => a.title.localeCompare(b.title),
      ...getColumnSearchProps({
        dataIndex: 'title',
        label: 'Title',
      }),
    },
    {
      dataIndex: 'company',
      key: 'company',
      title: 'Company',
      filteredValue: filteredInfo?.company || null,
      sorter: (a, b) => a.company.localeCompare(b.company),
      ...getColumnSearchProps({
        dataIndex: 'company',
        label: 'Company',
      }),
    },
    {
      dataIndex: 'address',
      key: 'address',
      title: 'Address',
      filteredValue: filteredInfo?.address || null,
      sorter: (a, b) => a.address.localeCompare(b.address),
      ...getColumnSearchProps({
        dataIndex: 'address',
        label: 'Address',
      }),
    },
    {
      dataIndex: 'telnos',
      key: 'telnos',
      title: 'Tel Nos',
      filteredValue: filteredInfo?.telnos || null,
      sorter: (a, b) => a.telnos.localeCompare(b.telnos),
      ...getColumnSearchProps({
        dataIndex: 'telnos',
        label: 'Tel Nos',
      }),
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'Email',
      filteredValue: filteredInfo?.email || null,
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getColumnSearchProps({
        dataIndex: 'email',
        label: 'Email',
      }),
    },
    {
      key: 'actions',
      render: (_, record) => {
        if (
          contactState.is.deleting &&
          contactState.is.deletingId === record.id
        ) {
          return <Spin tip="Deleting..." spinning />
        } else {
          return (
            <Popover
              content={
                <Menu>
                  <Menu.Item>
                    <Link onClick={() => editContact(record)}>Edit</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Popconfirm
                      title="Are you sure you want to delete this contact?"
                      onConfirm={() =>
                        dispatch(contactEffects.deleteContact(record.id))
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Text type="danger">Delete</Text>
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="primary">Actions</Button>
            </Popover>
          )
        }
      },
    },
  ]

  return (
    <>
      <ListPageHeader
        title="Contacts"
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
        columns={columns}
        filteredInfo={filteredInfo}
        lastUpdated={contactState.lastUpdated}
        onCreate={() => setModalVisible(true)}
        onRefresh={() => dispatch(contactEffects.fetchContacts())}
        refreshButtonDisabled={contactState.is.fetching}
      />
      <Row justify="center">
        <Col span={24}>
          <Spin
            size="large"
            spinning={contactState.is.fetching}
            tip="Loading Contacts..."
          >
            <Table
              columns={columns}
              dataSource={contactState.list.map((c) => ({
                ...c,
                key: c.id,
              }))}
              onChange={(_, filters) => setFilteredInfo(filters)}
              pagination={{ pageSize: 100 }}
            ></Table>
          </Spin>
        </Col>
      </Row>
      <ContactForm
        editContactData={editContactData}
        setEditContactData={setEditContactData}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  )
}
