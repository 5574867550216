import moment from 'moment'
import api from '../api'
import { effects } from '../store/modules/auth'

export const accessTokenValidAndNotExpired = (accessToken) => {
  try {
    const { exp } = JSON.parse(atob(accessToken.split('.')[1]))
    const now = parseInt(moment().format('X'), 10)
    return exp - now > 0
  } catch (_) {
    return false
  }
}

const callAPI = async (
  { rejectWithValue },
  accessToken,
  type,
  endpoint,
  data
) => {
  try {
    return await api(type, endpoint, {
      ...data,
      accessToken,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
}

export default async (thunkAPI, type, endpoint, data = {}) => {
  const { accessToken } = thunkAPI.getState().auth
  if (accessToken) {
    if (accessTokenValidAndNotExpired(accessToken)) {
      const action = await thunkAPI.dispatch(
        effects.fetchUserDetails(accessToken)
      )
      if (action.type === 'fetchUserDetails/fulfilled') {
        return callAPI(thunkAPI, accessToken, type, endpoint, data)
      } else if (action.type === 'fetchUserDetails/rejected') {
        thunkAPI.dispatch(effects.logout(accessToken))
      }
    } else {
      const { refreshToken } = thunkAPI.getState().auth
      const action = await thunkAPI.dispatch(
        effects.fetchAccessToken({ refreshToken })
      )
      if (action.type === 'fetchAccessToken/fulfilled') {
        return callAPI(
          thunkAPI,
          action.payload.access_token,
          type,
          endpoint,
          data
        )
      } else if (action.type === 'fetchAccessToken/rejected') {
        thunkAPI.dispatch(effects.logout(accessToken))
      }
    }
  } else {
    thunkAPI.dispatch(effects.logout(accessToken))
  }
}
