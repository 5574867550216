import React from 'react'
import { useHistory } from 'react-router-dom'
import { Col, PageHeader, Row, Typography } from 'antd'
import * as Forms from './forms'
import { capitaliseFirstLetter } from '../../utils'
const { Title } = Typography

export default ({ label }) => {
  const history = useHistory()
  const { backLink } = history.location.state
  const Form = Forms[capitaliseFirstLetter(label)]

  return (
    <Row justify="center">
      <Col xs={20} md={18} xl={16}>
        <PageHeader
          onBack={() => history.push(backLink.route)}
          title={backLink.label}
        >
          <Title>Edit {label}</Title>
          <Form backLink={backLink} />
        </PageHeader>
      </Col>
    </Row>
  )
}
