import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { effects as authEffects } from './auth'
import { authify } from '../../utils'

const namespace = 'organisation'

export const effects = {
  createOrganisation: createAsyncThunk(
    'createOrganisation',
    async (data, thunkAPI) => authify(thunkAPI, 'post', '/organizations', data)
  ),
  fetchOrganisations: createAsyncThunk(
    'fetchOrganisations',
    async (_, thunkAPI) => authify(thunkAPI, 'get', '/organizations')
  ),
  saveOrganisation: createAsyncThunk(
    'saveOrganisation',
    async ({ id, data }, thunkAPI) =>
      authify(thunkAPI, 'patch', `/organizations/${id}`, data)
  ),
}

const initialState = {
  error: {
    creating: null,
    fetching: null,
    saving: null,
  },
  is: {
    creating: false,
    fetching: false,
    saving: false,
  },
  success: {
    creating: false,
    fetching: false,
    saving: false,
  },
  lastUpdated: null,
  list: [],
}

export const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset: () => initialState,
    resetErrorCreating: (state) => {
      state.error.creating = initialState.error.creating
    },
    resetErrorFetching: (state) => {
      state.error.fetching = initialState.error.fetching
    },
    resetErrorSaving: (state) => {
      state.error.saving = initialState.error.saving
    },
    resetSuccessCreating: (state) => {
      state.success.creating = initialState.success.creating
    },
    resetSuccessFetching: (state) => {
      state.success.fetching = initialState.success.fetching
    },
    resetSuccessSaving: (state) => {
      state.success.saving = initialState.success.saving
    },
  },
  extraReducers: {
    [effects.createOrganisation.pending]: (state) => {
      state.is.creating = true
      state.error.creating = initialState.error.creating
      state.success.creating = initialState.error.creating
    },
    [effects.createOrganisation.rejected]: (state, { payload }) => {
      state.is.creating = initialState.is.creating
      state.error.creating = payload
      state.success.creating = initialState.success.creating
    },
    [effects.createOrganisation.fulfilled]: (state) => {
      state.is.creating = initialState.is.creating
      state.error.creating = initialState.error.creating
      state.success.creating = true
    },
    [effects.fetchOrganisations.pending]: (state) => {
      state.is.fetching = true
      state.error.fetching = initialState.error.fetching
      state.success.fetching = initialState.error.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchOrganisations.rejected]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = payload
      state.success.fetching = initialState.success.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchOrganisations.fulfilled]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = initialState.error.fetching
      state.success.fetching = true
      state.list = payload || []
      state.lastUpdated = moment().format('X')
    },
    [effects.saveOrganisation.pending]: (state) => {
      state.is.saving = true
      state.error.saving = initialState.error.saving
      state.success.saving = initialState.error.saving
    },
    [effects.saveOrganisation.rejected]: (state, { payload }) => {
      state.is.saving = initialState.is.saving
      state.error.saving = payload
      state.success.saving = initialState.success.saving
    },
    [effects.saveOrganisation.fulfilled]: (state) => {
      state.is.saving = initialState.is.saving
      state.error.saving = initialState.error.saving
      state.success.saving = true
    },
    [authEffects.logout.fulfilled]: () => initialState,
  },
})

export const selectors = {
  state: (state) => state[namespace],
}

export default reducer
