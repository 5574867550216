import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import {
  Button,
  Col,
  Input,
  Menu,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import {
  actions as assessmentActions,
  effects as assessmentEffects,
  reselectors as assessmentReselectors,
  selectors as assessmentSelectors,
} from '../../../store/modules/assessment'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { RAStatusTag, ListPageHeader } from '../../../components'
import { StatusHistory } from '../../../modals'
import { statuses } from '../../../utils'

const { Text } = Typography

export default () => {
  const history = useHistory()
  const location = useLocation()

  const assessmentState = useSelector(assessmentSelectors.state)
  const authState = useSelector(authSelectors.state)

  const assessmentsList =
    assessmentReselectors.assessmentsWithLastUpdated(assessmentState)

  const [filteredInfo, setFilteredInfo] = useState(null)
  const [searchedColumn, setSearchedColumn] = useState({})
  const [searchText, setSearchText] = useState({})
  const [statusHistory, setStatusHistory] = useState(null)
  const [statusHistoryProjectNumber, setStatusHistoryProjectNumber] =
    useState(null)
  const [statusHistorySiteName, setStatusHistorySiteName] = useState(null)
  const [statusHistoryVisible, setStatusHistoryVisible] = useState(false)

  const clearAllFilters = () => {
    setFilteredInfo(null)
    setSearchText({})
    setSearchedColumn({})
  }

  const clearFilter = (dataIndex, confirm = () => {}) => {
    setFilteredInfo({ ...filteredInfo, [dataIndex]: null })
    setSearchText({ ...searchText, [dataIndex]: null })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: null })
    confirm()
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      assessmentState.list.length === 0 &&
      !assessmentState.success.creating &&
      !assessmentState.success.deleting
    ) {
      dispatch(assessmentEffects.fetchAssessments())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (assessmentState.success.approving) {
      notification.success({ message: 'Assessment Approved' })
      dispatch(assessmentActions.resetSuccessApproving())
      dispatch(assessmentEffects.fetchAssessments())
    } else if (assessmentState.success.creating) {
      notification.success({ message: 'Assessment Created' })
      dispatch(assessmentActions.resetSuccessCreating())
      dispatch(assessmentEffects.fetchAssessments())
    } else if (assessmentState.success.deleting) {
      notification.success({ message: 'Assessment Deleted' })
      dispatch(assessmentActions.resetSuccessDeleting())
      dispatch(assessmentEffects.fetchAssessments())
    } else if (assessmentState.success.rewinding) {
      notification.success({ message: 'Assessment Rewound' })
      dispatch(assessmentActions.resetSuccessRewinding())
      dispatch(assessmentEffects.fetchAssessments())
    } else if (assessmentState.success.unapproving) {
      notification.success({ message: 'Assessment Unapproved' })
      dispatch(assessmentActions.resetSuccessUnapproving())
      dispatch(assessmentEffects.fetchAssessments())
    } else if (assessmentState.error.approving) {
      notification.error({ message: 'Could not approve Assessment' })
      dispatch(assessmentActions.resetErrorApproving())
    } else if (assessmentState.error.deleting) {
      notification.error({ message: 'Could not delete Assessment' })
      dispatch(assessmentActions.resetErrorDeleting())
    } else if (assessmentState.error.rewinding) {
      notification.error({ message: 'Could not rewind Assessment' })
      dispatch(assessmentActions.resetErrorRewinding())
    } else if (assessmentState.error.unapproving) {
      notification.error({ message: 'Could not unapprove Assessment' })
      dispatch(assessmentActions.resetErrorUnapproving())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assessmentState.success.approving,
    assessmentState.success.creating,
    assessmentState.success.deleting,
    assessmentState.success.rewinding,
    assessmentState.success.unapproving,
    assessmentState.error.approving,
    assessmentState.error.deleting,
    assessmentState.error.rewinding,
    assessmentState.error.unapproving,
  ])

  const getColumnSearchProps = ({ dataIndex, label }) => {
    let searchInput
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node
            }}
            placeholder={`Filter ${label}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilter(dataIndex, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select())
        }
      },
      render: (text) =>
        searchedColumn[dataIndex] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText[dataIndex]]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText({ ...searchText, [dataIndex]: selectedKeys[0] })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: true })
  }

  const columns = [
    {
      title: 'Project Number',
      dataIndex: 'project_number',
      filteredValue: filteredInfo?.project_number || null,
      ...getColumnSearchProps({
        dataIndex: 'project_number',
        label: 'Project Number',
      }),
    },
    {
      title: 'Site',
      dataIndex: 'site_name',
      filteredValue: filteredInfo?.site_name || null,
      sorter: (a, b) => a.site_name.localeCompare(b.site_name),
      ...getColumnSearchProps({
        dataIndex: 'site_name',
        label: 'Sites',
      }),
    },
    {
      title: 'Organisation',
      dataIndex: 'client_name',
      filteredValue: filteredInfo?.client_name || null,
      sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      ...getColumnSearchProps({
        dataIndex: 'client_name',
        label: 'Organisations',
      }),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filteredValue: filteredInfo?.status || null,
      filters: Object.keys(statuses).map((status) => ({
        text: <RAStatusTag status={status} style={{ cursor: 'pointer' }} />,
        value: status,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        const statusesArray = Array.from(Object.values(statuses))
        return (
          statusesArray.indexOf(statuses[a.status]) -
          statusesArray.indexOf(statuses[b.status])
        )
      },
      render: (text, record) => (
        <RAStatusTag
          onClick={() => {
            setStatusHistory(record.activity)
            setStatusHistoryProjectNumber(record.project_number)
            setStatusHistorySiteName(record.site_name)
            setStatusHistoryVisible(true)
          }}
          status={text}
          style={{ cursor: 'pointer' }}
        />
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'last_updated',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.last_updated).format('X') - moment(b.last_updated).format('X'),
    },
    {
      title: 'Surveyor',
      dataIndex: 'surveyor_name',
      filteredValue: filteredInfo?.surveyor_name || null,
      sorter: (a, b) => a.surveyor_name.localeCompare(b.surveyor_name),
      ...getColumnSearchProps({
        dataIndex: 'surveyor_name',
        label: 'Surveyors',
      }),
    },
    {
      title: 'Project Manager',
      dataIndex: 'project_manager_name',
      filteredValue: filteredInfo?.project_manager_name || null,
      sorter: (a, b) =>
        a.project_manager_name.localeCompare(b.project_manager_name),
      ...getColumnSearchProps({
        dataIndex: 'project_manager_name',
        label: 'Project Managers',
      }),
    },
    {
      title: 'Reviewer',
      dataIndex: 'reviewer_name',
      filteredValue: filteredInfo?.reviewer_name || null,
      sorter: (a, b) => a.reviewer_name.localeCompare(b.reviewer_name),
      ...getColumnSearchProps({
        dataIndex: 'reviewer_name',
        label: 'Reviewers',
      }),
    },
    {
      key: 'actions',
      render: (_, record) => {
        if (
          assessmentState.is.approving &&
          assessmentState.is.approvingId === record.id
        ) {
          return <Spin tip="Approving..." spinning />
        } else if (
          assessmentState.is.unapproving &&
          assessmentState.is.unapprovingId === record.id
        ) {
          return <Spin tip="Unapproving..." spinning />
        } else if (
          assessmentState.is.rewinding &&
          assessmentState.is.rewindingId === record.id
        ) {
          return <Spin tip="Rewinding..." spinning />
        } else if (
          assessmentState.is.deleting &&
          assessmentState.is.deletingId === record.id
        ) {
          return <Spin tip="Deleting..." spinning />
        } else {
          return (
            <Popover
              content={
                <Menu>
                  {['draft', 'completed', 'approved'].includes(
                    record.status
                  ) && [
                    <Menu.Item>
                      <a
                        href={`${process.env.REACT_APP_ASSESSMENTS_URL}/assessments/${record.id}/pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {record.status === 'approved' ? 'Download' : 'Preview'}{' '}
                        PDF
                      </a>
                    </Menu.Item>,
                  ]}
                  {['created', 'draft', 'completed'].includes(
                    record.status
                  ) && (
                    <Menu.Item>
                      <Link
                        to={{
                          pathname: `${location.pathname}/${record.id}/edit`,
                          state: {
                            backLink: {
                              route: '/assessments',
                              label: 'Back to list',
                            },
                          },
                        }}
                      >
                        Edit
                      </Link>
                    </Menu.Item>
                  )}
                  {[
                    '0249c3a6-ab3a-11ea-92b9-1227dd150790', // Devan
                    '0285c428-ab3a-11ea-a53b-1227dd150790', // Joe
                  ].includes(authState.id) &&
                    record.status === 'completed' && (
                      <Menu.Item>
                        <Popconfirm
                          title="Are you sure you want to approve this assessment?"
                          onConfirm={() =>
                            dispatch(
                              assessmentEffects.approveAssessment(record.id)
                            )
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Text style={{ color: 'rgb(52, 199, 89)' }}>
                            Approve
                          </Text>
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  {[
                    '0249c3a6-ab3a-11ea-92b9-1227dd150790', // Devan
                    '0285c428-ab3a-11ea-a53b-1227dd150790', // Joe
                  ].includes(authState.id) &&
                    record.status === 'approved' && (
                      <Menu.Item>
                        <Popconfirm
                          title="Are you sure you want to unapprove this assessment?"
                          onConfirm={() =>
                            dispatch(
                              assessmentEffects.unapproveAssessment(record.id)
                            )
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Text type="danger">Unapprove</Text>
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  {['started', 'editing'].includes(record.status) && (
                    <Menu.Item>
                      <Popconfirm
                        title="Are you sure you want to rewind this assessment?"
                        onConfirm={() =>
                          dispatch(
                            assessmentEffects.rewindAssessment(record.id)
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Text type="danger">Rewind</Text>
                      </Popconfirm>
                    </Menu.Item>
                  )}
                  {record.status === 'created' && (
                    <Menu.Item>
                      <Popconfirm
                        title="Are you sure you want to delete this assessment?"
                        onConfirm={() =>
                          dispatch(
                            assessmentEffects.deleteAssessment(record.id)
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Text type="danger">Delete</Text>
                      </Popconfirm>
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Button type="primary">Actions</Button>
            </Popover>
          )
        }
      },
    },
  ]

  return (
    <>
      <ListPageHeader
        title="Assessments"
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
        columns={columns}
        filteredInfo={filteredInfo}
        lastUpdated={assessmentState.lastUpdated}
        onCreate={() => history.push(`/assessments/create`)}
        onRefresh={() => dispatch(assessmentEffects.fetchAssessments())}
        refreshButtonDisabled={assessmentState.is.fetching}
      />
      <Row justify="center">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Spin
                size="large"
                spinning={assessmentState.is.fetching}
                tip="Loading Assessments..."
              >
                <Table
                  columns={columns}
                  dataSource={assessmentsList.map((a) => ({
                    ...a,
                    key: a.id,
                  }))}
                  onChange={(_, filters) => setFilteredInfo(filters)}
                  pagination={{ pageSize: 100 }}
                />
              </Spin>
            </Col>
          </Row>
        </Col>
      </Row>
      <StatusHistory
        statusHistory={statusHistory}
        setStatusHistory={setStatusHistory}
        projectNumber={statusHistoryProjectNumber}
        setStatusHistoryProjectNumber={setStatusHistoryProjectNumber}
        siteName={statusHistorySiteName}
        setStatusHistorySiteName={setStatusHistorySiteName}
        visible={statusHistoryVisible}
        setVisible={setStatusHistoryVisible}
      />
    </>
  )
}
