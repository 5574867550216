import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
  Alert,
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Spin,
  Typography,
} from 'antd'
import {
  CloudUploadOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { ContactForm } from '../../../forms'
import { Option, Select } from '../../../components'
import {
  actions as assessmentActions,
  effects as assessmentEffects,
  nextReviewPeriods,
  reselectors as assessmentReselectors,
  selectors as assessmentSelectors,
} from '../../../store/modules/assessment'
import { selectors as authSelectors } from '../../../store/modules/auth'
import {
  effects as contactEffects,
  selectors as contactSelectors,
} from '../../../store/modules/contact'
import {
  effects as organisationEffects,
  selectors as organisationSelectors,
} from '../../../store/modules/organisation'
import {
  effects as siteEffects,
  selectors as siteSelectors,
} from '../../../store/modules/site'
import { objectFilter, objectMap } from '../../../utils'
import { accessTokenValidAndNotExpired } from '../../../utils/authify'

const { Panel } = Collapse
const { Title } = Typography

export default () => {
  const params = useParams()

  const assessmentState = useSelector(assessmentSelectors.state)
  const authState = useSelector(authSelectors.state)
  const contactState = useSelector(contactSelectors.state)
  const organisationState = useSelector(organisationSelectors.state)
  const siteState = useSelector(siteSelectors.state)

  const { accessToken } = authState
  const contactsList = contactState.list
  const organisationsList = organisationState.list
  const sitesList = siteState.list
  const sitesCompaniesList = assessmentState.sitesCompanies.list
  const sitesUsersList = assessmentState.sitesUsers.list

  const [modalVisible, setModalVisible] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(assessmentActions.resetSettingsFormErrorSavingMessages())
    dispatch(assessmentActions.resetSettingsFormValues())
    dispatch(assessmentEffects.fetchSettings(params.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accessTokenValidAndNotExpired(accessToken)) {
      if (organisationsList.length === 0) {
        dispatch(organisationEffects.fetchOrganisations())
      }
      if (contactsList.length === 0) {
        dispatch(contactEffects.fetchContacts())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const [form] = Form.useForm()

  const contactsSections = [
    {
      label: 'Duty Holder',
      fieldLabel: 'dutyHolder',
    },
    {
      label: 'Nominated Responsible Person',
      fieldLabel: 'nominated',
    },
    {
      label: 'Deputy Nom. Resp. Person',
      fieldLabel: 'deputyNominated',
    },
    {
      label: 'Health & Safety Manager or Advisor',
      fieldLabel: 'healthSafety',
    },
    {
      label: 'Responsible Person',
      fieldLabel: 'personsResponsible',
    },
    {
      label: 'Water Hygiene Contractor',
      fieldLabel: 'waterHygiene',
    },
  ]

  const onFinish = (formData) => {
    const data = {
      ...objectFilter(
        formData,
        ([fieldName]) =>
          !contactsSections
            .map(({ fieldLabel }) => fieldLabel)
            .some((fieldLabel) => fieldName.startsWith(fieldLabel))
      ),
      site_id: sitesList.find((site) => site.name === formData.site_name).id,
      client_id: sitesList.find((site) => site.name === formData.site_name)
        .client_id,
      company_id: sitesCompaniesList.find(
        (company) => company.label === formData.company_name
      ).value,
      next_review_at: moment(formData.assessment_at, 'YYYY-MM-DD')
        .add(
          formData.next_review_period.split(' ')[0],
          formData.next_review_period.split(' ')[1]
        )
        .format('YYYY-MM-DD'),
      surveyor_id: sitesUsersList.find(
        (user) => user.label === formData.surveyor_name
      ).value,
      project_manager_id: sitesUsersList.find(
        (user) => user.label === formData.project_manager_name
      ).value,
      reviewer_id: sitesUsersList.find(
        (user) => user.label === formData.reviewer_name
      ).value,
      contacts: objectMap(
        objectFilter(formData, ([fieldName]) =>
          contactsSections.some(
            ({ fieldLabel }) =>
              fieldName.startsWith(fieldLabel) && !fieldName.endsWith('Lookup')
          )
        ),
        ([fieldName, fieldValue]) => ({ [fieldName]: fieldValue || '' })
      ),
    }
    dispatch(assessmentEffects.saveSettings({ id: params.id, data }))
  }

  const onSiteChange = (name) => {
    const siteId = sitesList.find((site) => site.name === name).id
    Promise.resolve()
      .then(async () => {
        if (assessmentState.sitesUsers.id !== siteId) {
          await dispatch(assessmentEffects.fetchSitesUsers(siteId))
        }
      })
      .then(async () => {
        if (assessmentState.sitesCompanies.id !== siteId) {
          await dispatch(assessmentEffects.fetchSitesCompanies(siteId))
        }
      })
    form.setFieldsValue({
      client: sitesList.find((site) => site.name === name).client_name,
    })
  }

  useEffect(() => {
    if (assessmentState.settingsForm.success.fetching) {
      form.setFieldsValue(
        assessmentReselectors.getInitialSettingsFormValues(assessmentState)
      )

      const siteId = assessmentState.settingsForm.values.site_id

      if (assessmentState.sitesUsers.id !== siteId) {
        dispatch(assessmentEffects.fetchSitesUsers(siteId)).then(() => {
          if (assessmentState.sitesCompanies.id !== siteId) {
            dispatch(assessmentEffects.fetchSitesCompanies(siteId))
          }
        })
      }
      dispatch(assessmentActions.resetSettingsFormSuccessFetching())
    } else if (assessmentState.settingsForm.is.saving) {
      window.scrollTo(0, 0)
    }
    if (assessmentState.settingsForm.success.saving) {
      notification.success({ message: 'Assessment Settings Saved' })
      dispatch(assessmentActions.resetSettingsFormSuccessSaving())
      dispatch(assessmentEffects.fetchAssessments())
    } else if (assessmentState.settingsForm.error.saving) {
      notification.error({ message: 'Could not save Assessment Settings' })
      dispatch(assessmentActions.resetSettingsFormErrorSaving())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assessmentState.settingsForm.success.fetching,
    assessmentState.settingsForm.is.saving,
    assessmentState.settingsForm.success.saving,
    assessmentState.settingsForm.error.saving,
  ])

  const getFormItemHelp = (name) => {
    if (assessmentState.settingsForm.error.savingMessages?.errors) {
      return assessmentState.settingsForm.error.savingMessages.errors[name]
    }
  }

  const getFormItemValidateStatus = (name) => {
    if (
      assessmentState.settingsForm.error.savingMessages?.errors &&
      assessmentState.settingsForm.error.savingMessages.errors[name]
    ) {
      return 'error'
    }
  }

  let loadingMessage = ''
  if (assessmentState.settingsForm.is.fetching) {
    loadingMessage = 'Loading Assessment Settings...'
  } else if (assessmentState.settingsForm.is.saving) {
    loadingMessage = 'Saving Assessment Settings...'
  }

  return (
    <Collapse defaultActiveKey={['settings']}>
      <Panel header="Settings" key="settings">
        <Row align="middle" justify="center">
          <Col span={24}>
            <Spin
              size="large"
              spinning={
                assessmentState.settingsForm.is.fetching ||
                assessmentState.settingsForm.is.saving
              }
              tip={loadingMessage}
            >
              {assessmentState.settingsForm.error.savingMessages?.message &&
                !assessmentState.settingsForm.error.savingMessages
                  ?.exception && (
                  <>
                    <Alert
                      message={
                        assessmentState.settingsForm.error.savingMessages
                          .message
                      }
                      type="error"
                      showIcon
                    />
                    <Divider />
                  </>
                )}
              <Form form={form} onFinish={onFinish}>
                <Title level={3}>SITE &amp; ORGANISATION SETUP</Title>
                <Row>
                  <Col span={23}>
                    <Form.Item
                      label="Choose a Site"
                      name="site_name"
                      rules={[{ required: true, message: 'Site is required' }]}
                      help={getFormItemHelp('site_name')}
                      validateStatus={getFormItemValidateStatus('site_name')}
                    >
                      <Select
                        loading={siteState.is.fetching}
                        onChange={onSiteChange}
                        showSearch
                      >
                        {sitesList.map((site) => (
                          <Option value={site.name} key={site.id}>
                            {site.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      disabled={siteState.is.fetching}
                      icon={<SyncOutlined />}
                      onClick={() => dispatch(siteEffects.fetchSites())}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={23}>
                    <Form.Item
                      label="Client Organisation"
                      name="client_name"
                      rules={[
                        { required: true, message: 'Client is required' },
                      ]}
                      help={getFormItemHelp('client_name')}
                      validateStatus={getFormItemValidateStatus('client_name')}
                    >
                      <Select
                        loading={organisationState.is.fetching}
                        showSearch
                      >
                        {organisationsList.map((organisation) => (
                          <Option
                            value={organisation.name}
                            key={organisation.id}
                          >
                            {organisation.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      disabled={organisationState.is.fetching}
                      icon={<SyncOutlined />}
                      onClick={() =>
                        dispatch(organisationEffects.fetchOrganisations())
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={23}>
                    <Form.Item
                      label="Company Organisation"
                      name="company_name"
                      rules={[
                        { required: true, message: 'Company is required' },
                      ]}
                      help={getFormItemHelp('company_name')}
                      validateStatus={getFormItemValidateStatus('company_name')}
                    >
                      <Select
                        disabled={!form.getFieldValue('site_name')}
                        placeholder={
                          !form.getFieldValue('site_name')
                            ? 'Please choose a site'
                            : ''
                        }
                        loading={assessmentState.sitesCompanies.is.fetching}
                        showSearch
                      >
                        {sitesCompaniesList.map((company) => (
                          <Option value={company.label} key={company.value}>
                            {company.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      disabled={
                        !form.getFieldValue('site_name') ||
                        assessmentState.sitesCompanies.is.fetching
                      }
                      icon={<SyncOutlined />}
                      onClick={() => {
                        const siteId = sitesList.find(
                          (site) =>
                            site.name === form.getFieldValue('site_name')
                        ).id
                        dispatch(assessmentEffects.fetchSitesCompanies(siteId))
                      }}
                    />
                  </Col>
                </Row>
                <Divider />
                <Title level={3}>ASSESSMENT DETAILS</Title>
                <Form.Item
                  label="Risk Assessment Title"
                  name="title"
                  rules={[{ required: true, message: 'Title is required' }]}
                  help={getFormItemHelp('title')}
                  validateStatus={getFormItemValidateStatus('title')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Date"
                  name="assessment_at"
                  getValueProps={(value) => ({
                    value: value ? moment(value, 'YYYY-MM-DD') : undefined,
                  })}
                  normalize={(value) => value.format('YYYY-MM-DD')}
                  rules={[{ required: true, message: 'Date is required' }]}
                  help={getFormItemHelp('assessment_at')}
                  validateStatus={getFormItemValidateStatus('assessment_at')}
                >
                  <DatePicker allowClear={false} />
                </Form.Item>
                <Form.Item
                  label="Next Review Period"
                  name="next_review_period"
                  rules={[
                    {
                      required: true,
                      message: 'Next Review Period is required',
                    },
                  ]}
                  help={getFormItemHelp('next_review_period')}
                  validateStatus={getFormItemValidateStatus(
                    'next_review_period'
                  )}
                >
                  <Select>
                    {nextReviewPeriods.map((nextReviewPeriod) => (
                      <Option value={nextReviewPeriod} key={nextReviewPeriod}>
                        {nextReviewPeriod}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Project Number"
                  name="project_number"
                  rules={[
                    {
                      required: true,
                      message: 'Project Number is required',
                    },
                  ]}
                  help={getFormItemHelp('project_number')}
                  validateStatus={getFormItemValidateStatus('project_number')}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Office" name="office">
                  <Input />
                </Form.Item>
                <Divider />
                <Title level={3}>ASSIGN ROLES</Title>
                <Row>
                  <Col span={23}>
                    <Form.Item
                      label="Surveyor"
                      name="surveyor_name"
                      rules={[
                        { required: true, message: 'Surveyor is required' },
                      ]}
                      help={getFormItemHelp('surveyor_name')}
                      validateStatus={getFormItemValidateStatus(
                        'surveyor_name'
                      )}
                    >
                      <Select
                        disabled={!form.getFieldValue('site_name')}
                        placeholder={
                          !form.getFieldValue('site_name')
                            ? 'Please choose a site'
                            : ''
                        }
                        loading={assessmentState.sitesUsers.is.fetching}
                        showSearch
                      >
                        {sitesUsersList.map((user) => (
                          <Option value={user.label} key={user.value}>
                            {user.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      disabled={
                        !form.getFieldValue('site_name') ||
                        assessmentState.sitesUsers.is.fetching
                      }
                      icon={<SyncOutlined />}
                      onClick={() => {
                        const siteId = sitesList.find(
                          (site) =>
                            site.name === form.getFieldValue('site_name')
                        ).id
                        dispatch(assessmentEffects.fetchSitesUsers(siteId))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={23}>
                    <Form.Item
                      label="Project Manager"
                      name="project_manager_name"
                      rules={[
                        {
                          required: true,
                          message: 'Project Manager is required',
                        },
                      ]}
                      help={getFormItemHelp('project_manager_name')}
                      validateStatus={getFormItemValidateStatus(
                        'project_manager_name'
                      )}
                    >
                      <Select
                        disabled={!form.getFieldValue('site_name')}
                        placeholder={
                          !form.getFieldValue('site_name')
                            ? 'Please choose a site'
                            : ''
                        }
                        loading={assessmentState.sitesUsers.is.fetching}
                        showSearch
                      >
                        {sitesUsersList.map((user) => (
                          <Option value={user.label} key={user.value}>
                            {user.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      disabled={
                        !form.getFieldValue('site_name') ||
                        assessmentState.sitesUsers.is.fetching
                      }
                      icon={<SyncOutlined />}
                      onClick={() => {
                        const siteId = sitesList.find(
                          (site) =>
                            site.name === form.getFieldValue('site_name')
                        ).id
                        dispatch(assessmentEffects.fetchSitesUsers(siteId))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={23}>
                    <Form.Item
                      label="Reviewer"
                      name="reviewer_name"
                      rules={[
                        { required: true, message: 'Reviewer is required' },
                      ]}
                      help={getFormItemHelp('reviewer_name')}
                      validateStatus={getFormItemValidateStatus(
                        'reviewer_name'
                      )}
                    >
                      <Select
                        disabled={!form.getFieldValue('site_name')}
                        placeholder={
                          !form.getFieldValue('site_name')
                            ? 'Please choose a site'
                            : ''
                        }
                        loading={assessmentState.sitesUsers.is.fetching}
                        showSearch
                      >
                        {sitesUsersList.map((user) => (
                          <Option value={user.label} key={user.value}>
                            {user.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      disabled={
                        !form.getFieldValue('site_name') ||
                        assessmentState.sitesUsers.is.fetching
                      }
                      icon={<SyncOutlined />}
                      onClick={() => {
                        const siteId = sitesList.find(
                          (site) =>
                            site.name === form.getFieldValue('site_name')
                        ).id
                        dispatch(assessmentEffects.fetchSitesUsers(siteId))
                      }}
                    />
                  </Col>
                </Row>
                <Divider />
                <Title level={3}>WATER HYGIENE RESPONSIBILITY CONTACTS</Title>
                <Row justify="end">
                  <Col>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => setModalVisible(true)}
                    >
                      Create New Contact
                    </Button>
                  </Col>
                </Row>
                {contactsSections.map((contactSection) => (
                  <>
                    <Title level={4}>{contactSection.label}</Title>
                    <Row>
                      <Col span={23}>
                        <Form.Item
                          label="Lookup"
                          name={`${contactSection.fieldLabel}Lookup`}
                          help={getFormItemHelp(
                            `${contactSection.fieldLabel}Lookup`
                          )}
                          validateStatus={getFormItemValidateStatus(
                            `${contactSection.fieldLabel}Lookup`
                          )}
                        >
                          <Select
                            loading={contactState.is.fetching}
                            onChange={(value) => {
                              const contact = contactsList.find(
                                (contact) => contact.lookup === value
                              )
                              form.setFieldsValue({
                                [`${contactSection.fieldLabel}Name`]:
                                  contact?.name,
                                [`${contactSection.fieldLabel}Title`]:
                                  contact?.title,
                                [`${contactSection.fieldLabel}Company`]:
                                  contact?.company,
                                [`${contactSection.fieldLabel}Address`]:
                                  contact?.address,
                                [`${contactSection.fieldLabel}TelNos`]:
                                  contact?.telnos,
                                [`${contactSection.fieldLabel}Email`]:
                                  contact?.email,
                              })
                            }}
                            allowClear
                            showSearch
                          >
                            {contactsList.map((contact, index) => (
                              <Option value={contact.lookup} key={index}>
                                {contact.lookup}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <Button
                          disabled={contactState.is.fetching}
                          icon={<SyncOutlined />}
                          onClick={() =>
                            dispatch(contactEffects.fetchContacts())
                          }
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={12}>
                        <Form.Item
                          label="Name"
                          name={`${contactSection.fieldLabel}Name`}
                        >
                          <Input disabled />
                        </Form.Item>
                        <Form.Item
                          label="Company"
                          name={`${contactSection.fieldLabel}Company`}
                        >
                          <Input disabled />
                        </Form.Item>
                        <Form.Item
                          label="Tel Nos"
                          name={`${contactSection.fieldLabel}TelNos`}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          label="Title"
                          name={`${contactSection.fieldLabel}Title`}
                        >
                          <Input disabled />
                        </Form.Item>
                        <Form.Item
                          label="Address"
                          name={`${contactSection.fieldLabel}Address`}
                        >
                          <Input disabled />
                        </Form.Item>
                        <Form.Item
                          label="Email"
                          name={`${contactSection.fieldLabel}Email`}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ))}
                <Row justify="end">
                  <Col>
                    <Form.Item>
                      <Button
                        type="primary"
                        icon={<CloudUploadOutlined />}
                        htmlType="submit"
                      >
                        Save Settings
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <ContactForm
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
              />
            </Spin>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}
