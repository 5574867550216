import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api'

const namespace = 'auth'

export const effects = {
  fetchAccessToken: createAsyncThunk(
    'fetchAccessToken',
    async ({ code, refreshToken }) =>
      await api('auth', 'token', { code, refresh_token: refreshToken })
  ),
  fetchUserDetails: createAsyncThunk(
    'fetchUserDetails',
    async (accessToken) => await api('auth', 'me', { accessToken })
  ),
  logout: createAsyncThunk(
    'logout',
    async (accessToken) => await api('auth', 'logout', { accessToken })
  ),
}

const initialState = {
  accessToken: null,
  id: null,
  firstName: null,
  lastName: null,
  refreshToken: null,
  error: {
    fetchingAccessToken: null,
    fetchingUserDetails: null,
    loggingOut: null,
  },
  is: {
    fetchingAccessToken: false,
    fetchingUserDetails: false,
    loggingOut: false,
  },
  success: {
    fetchingAccessToken: false,
    fetchingUserDetails: false,
    loggingOut: false,
  },
}

export const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset: () => initialState,
    resetErrorFetchingAccessToken: (state) => {
      state.error.fetchingAccessToken = initialState.error.fetchingAccessToken
    },
    resetErrorFetchingUserDetails: (state) => {
      state.error.fetchingUserDetails = initialState.error.fetchingUserDetails
    },
    resetErrorLoggingOut: (state) => {
      state.error.loggingOut = initialState.error.loggingOut
    },
    resetSuccessFetchingAccessToken: (state) => {
      state.success.fetchingAccessToken =
        initialState.success.fetchingAccessToken
    },
    resetSuccessFetchingUserDetails: (state) => {
      state.success.fetchingUserDetails =
        initialState.success.fetchingUserDetails
    },
  },
  extraReducers: {
    [effects.fetchAccessToken.pending]: (state) => {
      state.is.fetchingAccessToken = true
      state.error.fetchingAccessToken = initialState.error.fetchingAccessToken
      state.success.fetchingAccessToken =
        initialState.success.fetchingAccessToken
    },
    [effects.fetchAccessToken.rejected]: (state) => {
      state.is.fetchingAccessToken = initialState.is.fetchingAccessToken
      state.error.fetchingAccessToken = true
      state.success.fetchingAccessToken =
        initialState.success.fetchingAccessToken
    },
    [effects.fetchAccessToken.fulfilled]: (state, { payload }) => {
      state.is.fetchingAccessToken = initialState.is.fetchingAccessToken
      state.error.fetchingAccessToken = initialState.error.fetchingAccessToken
      state.success.fetchingAccessToken = true
      state.accessToken = payload.access_token
      state.refreshToken = payload.refresh_token
    },
    [effects.fetchUserDetails.pending]: (state) => {
      state.is.fetchingUserDetails = true
      state.error.fetchingUserDetails = initialState.error.fetchingUserDetails
      state.success.fetchingUserDetails =
        initialState.success.fetchingUserDetails
    },
    [effects.fetchUserDetails.rejected]: (state) => {
      state.is.fetchingUserDetails = initialState.is.fetchingUserDetails
      state.error.fetchingUserDetails = true
      state.success.fetchingUserDetails =
        initialState.success.fetchingUserDetails
    },
    [effects.fetchUserDetails.fulfilled]: (state, { payload }) => {
      state.is.fetchingUserDetails = initialState.is.fetchingUserDetails
      state.error.fetchingUserDetails = initialState.error.fetchingUserDetails
      state.success.fetchingUserDetails = true
      state.id = payload.id
      state.firstName = payload.first_name
      state.lastName = payload.last_name
    },
    [effects.logout.pending]: (state) => {
      state.is.loggingOut = true
      state.error.loggingOut = initialState.error.loggingOut
      state.success.loggingOut = initialState.success.loggingOut
    },
    [effects.logout.rejected]: (state) => {
      state.is.loggingOut = initialState.is.loggingOut
      state.error.loggingOut = true
      state.success.loggingOut = initialState.success.loggingOut
    },
    [effects.logout.fulfilled]: () => ({
      ...initialState,
      success: {
        ...initialState.success,
        loggingOut: true,
      },
    }),
  },
})

export const selectors = {
  state: (state) => state[namespace],
}

export default reducer
