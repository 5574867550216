import React from 'react'
import { useHistory } from 'react-router-dom'
import { Col, PageHeader, Row, Typography } from 'antd'
import * as Forms from './forms'
import { capitaliseFirstLetter } from '../../utils'
import './index.scss'

const { Title } = Typography

export default ({ listPageRoute, label }) => {
  const history = useHistory()
  const Form = Forms[capitaliseFirstLetter(label)]
  return (
    <Row justify="center">
      <Col xs={20} md={18} xl={16}>
        <PageHeader
          onBack={() => history.push(listPageRoute)}
          title="Back to list"
        >
          <Row>
            <Col span={24} className="create-page__container">
              <Title>Create a New {label}</Title>
              <Form listPageRoute={listPageRoute} />
            </Col>
          </Row>
        </PageHeader>
      </Col>
    </Row>
  )
}
