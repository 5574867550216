import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { effects as authEffects } from './auth'
import { authify } from '../../utils'

const namespace = 'site'

export const effects = {
  createSite: createAsyncThunk(
    'createSite',
    async ({ orgId, data }, thunkAPI) =>
      authify(thunkAPI, 'post', `/organizations/${orgId}/sites`, data)
  ),
  deleteSite: createAsyncThunk('deleteSite', async (id, thunkAPI) =>
    authify(thunkAPI, 'delete', `/sites/${id}`)
  ),
  fetchSites: createAsyncThunk('fetchSites', async (_, thunkAPI) =>
    authify(thunkAPI, 'get', '/sites')
  ),
  saveSite: createAsyncThunk('saveSite', async ({ siteId, data }, thunkAPI) =>
    authify(thunkAPI, 'patch', `/sites/${siteId}`, data)
  ),
}

const initialState = {
  error: {
    creating: null,
    deleting: null,
    fetching: null,
    saving: null,
  },
  is: {
    creating: false,
    deleting: false,
    deletingId: null,
    fetching: false,
    saving: false,
  },
  success: {
    creating: false,
    deleting: false,
    fetching: false,
    saving: false,
  },
  lastUpdated: null,
  list: [],
}

export const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset: () => initialState,
    resetErrorCreating: (state) => {
      state.error.creating = initialState.error.creating
    },
    resetErrorDeleting: (state) => {
      state.error.deleting = initialState.error.deleting
    },
    resetErrorFetching: (state) => {
      state.error.fetching = initialState.error.fetching
    },
    resetErrorSaving: (state) => {
      state.error.saving = initialState.error.saving
    },
    resetSuccessCreating: (state) => {
      state.success.creating = initialState.success.creating
    },
    resetSuccessDeleting: (state) => {
      state.success.deleting = initialState.success.deleting
    },
    resetSuccessFetching: (state) => {
      state.success.fetching = initialState.success.fetching
    },
    resetSuccessSaving: (state) => {
      state.success.saving = initialState.success.saving
    },
  },
  extraReducers: {
    [effects.createSite.pending]: (state) => {
      state.is.creating = true
      state.error.creating = initialState.error.creating
      state.success.creating = initialState.error.creating
    },
    [effects.createSite.rejected]: (state, { payload }) => {
      state.is.creating = initialState.is.creating
      state.error.creating = payload
      state.success.creating = initialState.success.creating
    },
    [effects.createSite.fulfilled]: (state, { payload }) => {
      state.is.creating = initialState.is.creating
      state.error.creating = initialState.error.creating
      state.success.creating = true
      state.list.push(payload[0])
    },
    [effects.deleteSite.pending]: (state, { meta: { arg } }) => {
      state.is.deleting = true
      state.is.deletingId = arg
      state.error.deleting = initialState.error.deleting
      state.success.deleting = initialState.error.deleting
    },
    [effects.deleteSite.rejected]: (state, { payload }) => {
      state.is.deleting = initialState.is.deleting
      state.is.deletingId = initialState.is.deletingId
      state.error.deleting = payload
      state.success.deleting = initialState.success.deleting
    },
    [effects.deleteSite.fulfilled]: (state, { meta: { arg } }) => {
      state.is.deleting = initialState.is.deleting
      state.is.deletingId = initialState.is.deletingId
      state.error.deleting = initialState.error.deleting
      state.success.deleting = true
      state.list = state.list.filter((site) => site.id !== arg)
    },
    [effects.fetchSites.pending]: (state) => {
      state.is.fetching = true
      state.error.fetching = initialState.error.fetching
      state.success.fetching = initialState.error.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchSites.rejected]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = payload
      state.success.fetching = initialState.success.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchSites.fulfilled]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = initialState.error.fetching
      state.success.fetching = true
      state.list = payload || []
      state.lastUpdated = moment().format('X')
    },
    [effects.saveSite.pending]: (state) => {
      state.is.saving = true
      state.error.saving = initialState.error.saving
      state.success.saving = initialState.error.saving
    },
    [effects.saveSite.rejected]: (state, { payload }) => {
      state.is.saving = initialState.is.saving
      state.error.saving = payload
      state.success.saving = initialState.success.saving
    },
    [effects.saveSite.fulfilled]: (state, { payload }) => {
      state.is.saving = initialState.is.saving
      state.error.saving = initialState.error.saving
      state.success.saving = true
      const savedSite = payload[0]
      state.list = state.list.map((site) => {
        if (savedSite.id !== site.id) {
          return site
        }
        return {
          ...site,
          ...savedSite,
        }
      })
    },
    [authEffects.logout.fulfilled]: () => initialState,
  },
})

export const selectors = {
  state: (state) => state[namespace],
}

export default reducer
