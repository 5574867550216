import React from 'react'
import { Col, Row, Tag } from 'antd'
import './ClearFilters.scss'
import { CloseOutlined } from '@ant-design/icons'

export default ({
  clearAllFilters,
  clearFilter,
  columns,
  filteredInfo,
  style,
}) => {
  const filters = filteredInfo
    ? Object.entries(filteredInfo).filter(([_, filter]) => filter !== null)
    : null

  return (
    filters?.length > 0 && (
      <Col className="clear-filters" style={style}>
        <Row gutter={8} style={{ margin: '12px 0' }}>
          <Col>Filters:</Col>
          <Col>
            <Row>
              {filters.map(([dataIndex]) => (
                <Col>
                  <Tag
                    className="clear-filters__tag"
                    onClick={() => clearFilter(dataIndex)}
                    style={{ cursor: 'pointer' }}
                  >
                    {columns.find((c) => c.dataIndex === dataIndex).title}
                    <CloseOutlined size="xs" />
                  </Tag>
                </Col>
              ))}
              {filters.length > 1 && (
                <Col>
                  <Tag
                    className="clear-filters__tag"
                    onClick={clearAllFilters}
                    style={{ cursor: 'pointer' }}
                  >
                    Clear All
                    <CloseOutlined size="xs" />
                  </Tag>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    )
  )
}
