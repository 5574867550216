import { configureStore } from '@reduxjs/toolkit'
import * as reducer from './modules'

const saveState = (state) => {
  if (state.hasOwnProperty('auth') && state.hasOwnProperty('site')) {
    localStorage.setItem(
      'com.l8log.assessments.web.state',
      JSON.stringify(state)
    )
  }
}

const getState = () => {
  try {
    const s = localStorage.getItem('com.l8log.assessments.web.state')

    if (s === null) return undefined
    return JSON.parse(s)
  } catch (e) {
    return undefined
  }
}

export const store = configureStore({ preloadedState: getState(), reducer })

store.subscribe(() => {
  const { auth, site } = store.getState()
  saveState({ auth, site })
})
