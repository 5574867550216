import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, notification, Row, Spin } from 'antd'
import {
  actions as authActions,
  effects as authEffects,
  selectors as authSelectors,
} from '../store/modules/auth'

export default () => {
  const history = useHistory()
  const location = useLocation()

  const authState = useSelector(authSelectors.state)

  const dispatch = useDispatch()

  useEffect(() => {
    const codePrefix = '?code='
    if (location.search.startsWith(codePrefix)) {
      const code = location.search.replace(codePrefix, '')
      dispatch(authEffects.fetchAccessToken({ code }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authState.success.fetchingAccessToken) {
      dispatch(authActions.resetSuccessFetchingAccessToken())
      dispatch(authEffects.fetchUserDetails(authState.accessToken))
    } else if (authState.error.fetchingAccessToken) {
      dispatch(authActions.resetErrorFetchingAccessToken())
      notification.error({ message: 'Could not log in' })
    } else if (authState.success.fetchingUserDetails) {
      dispatch(authActions.resetSuccessFetchingUserDetails())
      notification.success({
        message: `Logged in as ${authState.firstName} ${authState.lastName}`,
      })
      history.push('/')
    } else if (authState.error.fetchingUserDetails) {
      dispatch(authActions.reset())
      notification.error({ message: 'Could not log in' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authState.success.fetchingAccessToken,
    authState.success.fetchingUserDetails,
    authState.error.fetchingAccessToken,
    authState.error.fetchingUserDetails,
  ])

  let loadingMessage
  if (authState.is.fetchingAccessToken) {
    loadingMessage = 'Authorising...'
  } else if (authState.is.fetchingUserDetails) {
    loadingMessage = 'Getting your details...'
  }

  return (
    <Row align="middle" justify="center" style={{ minHeight: '100vh' }}>
      <Col>
        <Spin
          size="large"
          spinning={
            authState.is.fetchingAccessToken || authState.is.fetchingUserDetails
          }
          tip={loadingMessage}
        ></Spin>
        {!authState.is.fetchingAccessToken &&
          !authState.is.fetchingUserDetails && (
            <Button
              type="primary"
              onClick={() => {
                const loginRedirect = authState.success.loggingOut
                  ? process.env.REACT_APP_UMS_FORCE_LOGIN_URL
                  : process.env.REACT_APP_UMS_LOGIN_URL
                dispatch(authActions.reset())
                window.location.href = loginRedirect
              }}
            >
              Click here to log in
            </Button>
          )}
      </Col>
    </Row>
  )
}
