import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Upload,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { OrganisationForm } from '../../../forms'
import { ListPageHeader } from '../../../components'
import {
  effects as organisationEffects,
  selectors as organisationSelectors,
} from '../../../store/modules/organisation'

export default () => {
  const organisationState = useSelector(organisationSelectors.state)

  const [base64Logo, setBase64Logo] = useState(null)
  const [editOrganisationData, setEditOrganisationData] = useState(null)
  const [filteredInfo, setFilteredInfo] = useState(null)
  const [logoPreviewVisible, setLogoPreviewVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [searchedColumn, setSearchedColumn] = useState({})
  const [searchText, setSearchText] = useState({})

  const clearAllFilters = () => {
    setFilteredInfo(null)
    setSearchText({})
    setSearchedColumn({})
  }

  const clearFilter = (dataIndex, confirm = () => {}) => {
    setFilteredInfo({ ...filteredInfo, [dataIndex]: null })
    setSearchText({ ...searchText, [dataIndex]: null })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: null })
    confirm()
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (organisationState.list.length === 0) {
      dispatch(organisationEffects.fetchOrganisations())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getColumnSearchProps = ({ dataIndex, label }) => {
    let searchInput
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node
            }}
            placeholder={`Filter ${label}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilter(dataIndex, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select())
        }
      },
      render: (text) =>
        searchedColumn[dataIndex] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText[dataIndex]]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText({ ...searchText, [dataIndex]: selectedKeys[0] })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: true })
  }

  const editOrganisation = (record) => {
    setEditOrganisationData(record)
    setModalVisible(true)
  }

  const columns = [
    {
      dataIndex: 'company_number',
      key: 'company_number',
      title: 'Company Number',
      filteredValue: filteredInfo?.company_number || null,
      sorter: ({ company_number: a }, { company_number: b }) =>
        a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'company_number',
        label: 'Company Number',
      }),
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      defaultSortOrder: 'ascend',
      filteredValue: filteredInfo?.name || null,
      sorter: ({ name: a }, { name: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'name',
        label: 'Name',
      }),
    },
    {
      dataIndex: 'logo',
      key: 'logo',
      title: 'Logo',
      filteredValue: filteredInfo?.logo || null,
      render: (text) =>
        text && (
          <Upload
            fileList={[{ uid: '1', status: 'done', url: text }]}
            listType="picture-card"
            onPreview={() => {
              setBase64Logo(text)
              setLogoPreviewVisible(true)
            }}
            showUploadList={{ showRemoveIcon: false }}
          />
        ),
    },
    {
      dataIndex: 'address_line1',
      key: 'address_line1',
      title: 'Address Line 1',
      filteredValue: filteredInfo?.address_line1 || null,
      sorter: ({ address_line1: a }, { address_line1: b }) =>
        a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'address_line1',
        label: 'Address Line 1',
      }),
    },
    {
      dataIndex: 'address_line2',
      key: 'address_line2',
      title: 'Address Line 2',
      filteredValue: filteredInfo?.address_line2 || null,
      sorter: ({ address_line2: a }, { address_line2: b }) =>
        a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'address_line2',
        label: 'Address Line 2',
      }),
    },
    {
      dataIndex: 'city',
      key: 'city',
      title: 'City',
      filteredValue: filteredInfo?.city || null,
      sorter: ({ city: a }, { city: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'city',
        label: 'City',
      }),
    },
    {
      dataIndex: 'county',
      key: 'county',
      title: 'County',
      filteredValue: filteredInfo?.county || null,
      sorter: ({ county: a }, { county: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'county',
        label: 'County',
      }),
    },
    {
      dataIndex: 'post_code',
      key: 'post_code',
      title: 'Postcode',
      filteredValue: filteredInfo?.post_code || null,
      sorter: ({ post_code: a }, { post_code: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'post_code',
        label: 'Postcode',
      }),
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Popover
          content={
            <Menu>
              <Menu.Item>
                <Link onClick={() => editOrganisation(record)}>Edit</Link>
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="primary">Actions</Button>
        </Popover>
      ),
    },
  ]

  return (
    <>
      <ListPageHeader
        title="Organisations"
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
        columns={columns}
        filteredInfo={filteredInfo}
        lastUpdated={organisationState.lastUpdated}
        onCreate={() => setModalVisible(true)}
        onRefresh={() => dispatch(organisationEffects.fetchOrganisations())}
        refreshButtonDisabled={organisationState.is.fetching}
      />
      <Row justify="center">
        <Col span={24}>
          <Spin
            size="large"
            spinning={organisationState.is.fetching}
            tip="Loading Organisations..."
          >
            <Table
              columns={columns}
              dataSource={organisationState.list.map((o) => ({
                ...o,
                key: o.id,
              }))}
              onChange={(_, filters) => setFilteredInfo(filters)}
              pagination={{ pageSize: 100 }}
            />
          </Spin>
        </Col>
      </Row>
      <Modal
        visible={logoPreviewVisible}
        title="Logo"
        footer={null}
        onCancel={() => {
          setBase64Logo(null)
          setLogoPreviewVisible(false)
        }}
      >
        <img alt="logo" style={{ width: '100%' }} src={base64Logo} />
      </Modal>
      <OrganisationForm
        editOrganisationData={editOrganisationData}
        setEditOrganisationData={setEditOrganisationData}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  )
}
