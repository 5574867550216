import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Button,
  Col,
  Input,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd'
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { RAStatusTag, ClearFilters } from '../components'
import { selectors as authSelectors } from '../store/modules/auth'
import {
  effects as orgUserEffects,
  reselectors as orgUserReselectors,
  selectors as orgUserSelectors,
} from '../store/modules/orgUser'
import { activities, capitaliseFirstLetter, statuses } from '../utils'
import { accessTokenValidAndNotExpired } from '../utils/authify'
import './StatusHistory.scss'

export default ({
  projectNumber,
  setStatusHistoryProjectNumber,
  siteName,
  setStatusHistorySiteName,
  statusHistory,
  setStatusHistory,
  visible,
  setVisible,
}) => {
  const authState = useSelector(authSelectors.state)
  const orgUserState = useSelector(orgUserSelectors.state)

  const { accessToken } = authState
  const userList = orgUserReselectors.userList(orgUserState)

  const [filteredInfo, setFilteredInfo] = useState(null)
  const [searchedColumn, setSearchedColumn] = useState({})
  const [searchText, setSearchText] = useState({})

  const clearAllFilters = () => {
    setFilteredInfo(null)
    setSearchText({})
    setSearchedColumn({})
  }

  const clearFilter = (dataIndex, confirm = () => {}) => {
    setFilteredInfo({ ...filteredInfo, [dataIndex]: null })
    setSearchText({ ...searchText, [dataIndex]: null })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: null })
    confirm()
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (accessTokenValidAndNotExpired(accessToken) && userList.length === 0) {
      dispatch(orgUserEffects.fetchOrgUsers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const getColumnSearchProps = ({ dataIndex, label }) => {
    let searchInput
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node
            }}
            placeholder={`Filter ${label}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilter(dataIndex, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        let text = record[dataIndex]
        if (dataIndex === 'activity_by_id') {
          text = userList.find((user) => user.id === record[dataIndex]).title
        }
        return text.toString().toLowerCase().includes(value.toLowerCase())
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select())
        }
      },
      render: (text) => {
        let outputText = text
        if (dataIndex === 'activity_by_id') {
          outputText = userList.find((user) => user.id === text).title
        }
        return searchedColumn[dataIndex] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText[dataIndex]]}
            autoEscape
            textToHighlight={outputText}
          />
        ) : (
          outputText
        )
      },
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText({ ...searchText, [dataIndex]: selectedKeys[0] })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: true })
  }

  let version = 0
  const versionedStatusHistory = statusHistory
    ?.slice()
    .sort(
      (a, b) =>
        moment(a.activity_at).format('X') - moment(b.activity_at).format('X')
    )
    .map((statusActivity) => {
      if (statusActivity.activity === 'stored') {
        version++
      }
      return {
        ...statusActivity,
        version,
      }
    })

  const columns = [
    {
      title: 'Action',
      dataIndex: 'activity',
      filteredValue: filteredInfo?.activity || null,
      filters: activities.map((activity) => ({
        text: capitaliseFirstLetter(activity),
        value: activity,
      })),
      onFilter: (value, record) => record.activity.indexOf(value) === 0,
      sorter: (a, b) =>
        activities.indexOf(a.activity) - activities.indexOf(b.activity),
      render: (text, record) => {
        const syncError = JSON.parse(record.sync_error)
        let timestamp

        if (syncError !== null) {
          if (syncError.length > 1) {
            const timestampStrings = syncError.map((s) =>
              moment(s.timestamp).format('DD-MM-YYYY HH:mm:ss')
            )
            const firstStrings = timestampStrings.slice(
              0,
              timestampStrings.length - 1
            )
            const lastItem = timestampStrings[timestampStrings.length - 1]

            timestamp = `${firstStrings.join(', ')} and ${lastItem}`
          } else {
            timestamp = moment(syncError[0].timestamp).format(
              'DD-MM-YYYY HH:mm:ss'
            )
          }
        }

        return (
          <Row gutter={8}>
            <Col>{capitaliseFirstLetter(text)}</Col>
            {syncError !== null && (
              <Col>
                <Tooltip
                  title={`Sync error${
                    syncError.length > 1 ? 's' : ''
                  } occurred at ${timestamp}`}
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </Col>
            )}
          </Row>
        )
      },
    },
    {
      title: 'Timestamp',
      dataIndex: 'activity_at',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.activity_at).format('X') - moment(b.activity_at).format('X'),
      render: (text) => moment(text).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filteredValue: filteredInfo?.status || null,
      filters: Object.keys(statuses).map((status) => ({
        text: <RAStatusTag status={status} style={{ cursor: 'pointer' }} />,
        value: status,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        const statusesArray = Array.from(Object.values(statuses))
        return (
          statusesArray.indexOf(statuses[a.status]) -
          statusesArray.indexOf(statuses[b.status])
        )
      },
      render: (text) => <RAStatusTag status={text} />,
    },
    {
      title: 'User',
      dataIndex: 'activity_by_id',
      filteredValue: filteredInfo?.activity_by_id || null,
      sorter: ({ activity_by_id: a }, { activity_by_id: b }) => {
        const userA = userList.find((user) => user.id === a).title
        const userB = userList.find((user) => user.id === b).title
        return userA.localeCompare(userB)
      },
      ...getColumnSearchProps({
        dataIndex: 'activity_by_id',
        label: 'User',
      }),
    },
    {
      key: 'actions',
      render: (_, record) =>
        ['draft', 'completed', 'approved'].includes(record.status) && (
          <Popover
            content={
              <Menu>
                <Menu.Item>
                  <a
                    href={`${process.env.REACT_APP_ASSESSMENTS_URL}/assessments/${record.assessment_id}/pdf?version=${record.version}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {['draft', 'completed'].includes(record.status)
                      ? 'Preview'
                      : 'Download'}{' '}
                    PDF
                  </a>
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="primary">Actions</Button>
          </Popover>
        ),
    },
  ]

  return (
    <Modal
      afterClose={() => {
        clearAllFilters()
        setStatusHistory(null)
        setStatusHistoryProjectNumber(null)
        setStatusHistorySiteName(null)
      }}
      footer={null}
      onCancel={() => setVisible(false)}
      title={
        <>
          <strong>Status History</strong>: {siteName} ({projectNumber})
        </>
      }
      visible={visible}
    >
      <ClearFilters
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
        columns={columns}
        filteredInfo={filteredInfo}
        style={{ marginBottom: 16 }}
      />
      <Table
        columns={columns}
        dataSource={versionedStatusHistory}
        onChange={(_, filters) => setFilteredInfo(filters)}
      />
    </Modal>
  )
}
