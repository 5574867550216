import React from 'react'
import { Tag } from 'antd'
import { statuses } from '../utils'

const mapStatusToColor = {
  [statuses.created]: 'default',
  [statuses.started]: 'processing',
  [statuses.draft]: 'volcano',
  [statuses.completed]: 'orange',
  [statuses.editing]: 'error',
  [statuses.approved]: '#34C759',
}

export default ({ status, ...props }) => (
  <Tag color={mapStatusToColor[statuses[status]]} {...props}>
    {statuses[status].toUpperCase()}
  </Tag>
)
