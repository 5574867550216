import React from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { PlusOutlined, SyncOutlined } from '@ant-design/icons'
import ClearFilters from './ClearFilters'
import LastUpdated from './LastUpdated'

const { Title } = Typography

export default ({
  clearAllFilters,
  clearFilter,
  columns,
  onCreate,
  onRefresh,
  lastUpdated,
  refreshButtonDisabled,
  filteredInfo,
  title,
}) => (
  <Row justify="space-between">
    <Col>
      <Row align="top" gutter={24}>
        <Col>
          <Title>{title}</Title>
        </Col>
        <Col>
          <Row align="middle" gutter={16}>
            <Col>
              <Button
                disabled={refreshButtonDisabled}
                icon={<SyncOutlined />}
                onClick={onRefresh}
                style={{ margin: '7px 0' }}
              />
            </Col>
            {lastUpdated && (
              <Col>
                <LastUpdated timestamp={lastUpdated} />
              </Col>
            )}
          </Row>
        </Col>
        <ClearFilters
          clearAllFilters={clearAllFilters}
          clearFilter={clearFilter}
          columns={columns}
          filteredInfo={filteredInfo}
        />
      </Row>
    </Col>
    <Col>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={onCreate}
        style={{ margin: '7px 0' }}
      >
        Create New
      </Button>
    </Col>
  </Row>
)
