import { remoteTech, safeWater, rsk } from './logos'

export default [
  {
    id: 3,
    logo: remoteTech,
    company_number: '10422879',
    name: 'Remote Tech Ltd',
    description: 'Remote Tech',
    address_line1: '6th Floor',
    address_line2: '2 London Wall Place',
    city: 'London',
    county: '',
    post_code: 'EC2Y 5AU',
  },
  {
    id: 4,
    logo: safeWater,
    company_number: '10344786',
    name: 'Safe Water IO Ltd',
    description: '',
    address_line1: '6th Floor',
    address_line2: '2 London Wall Place',
    city: 'London',
    county: '',
    post_code: 'EC2Y 5AU',
  },
  {
    id: 5,
    logo: rsk,
    company_number: '03761340',
    name: 'RSK GROUP LIMITED',
    description: '',
    address_line1: 'Spring Lodge',
    address_line2: '172 Chester Road',
    city: 'Helsby',
    county: 'Cheshire',
    post_code: 'WA6 0AR',
  },
]
