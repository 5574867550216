import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Menu,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { SiteForm } from '../../../forms'
import { ListPageHeader } from '../../../components'
import {
  actions as siteActions,
  effects as siteEffects,
  selectors as siteSelectors,
} from '../../../store/modules/site'

const { Text } = Typography

export default () => {
  const siteState = useSelector(siteSelectors.state)

  const [editSiteData, setEditSiteData] = useState(false)
  const [filteredInfo, setFilteredInfo] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [searchedColumn, setSearchedColumn] = useState({})
  const [searchText, setSearchText] = useState({})

  const clearAllFilters = () => {
    setFilteredInfo(null)
    setSearchText({})
    setSearchedColumn({})
  }

  const clearFilter = (dataIndex, confirm = () => {}) => {
    setFilteredInfo({ ...filteredInfo, [dataIndex]: null })
    setSearchText({ ...searchText, [dataIndex]: null })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: null })
    confirm()
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (siteState.success.deleting) {
      notification.success({ message: 'Site deleted' })
      dispatch(siteActions.resetSuccessDeleting())
    } else if (siteState.error.deleting) {
      notification.error({ message: 'Could not delete site' })
      dispatch(siteActions.resetErrorDeleting())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteState.success.deleting, siteState.error.deleting])

  const getColumnSearchProps = ({ dataIndex, label }) => {
    let searchInput
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node
            }}
            placeholder={`Filter ${label}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilter(dataIndex, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select())
        }
      },
      render: (text) =>
        searchedColumn[dataIndex] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText[dataIndex]]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText({ ...searchText, [dataIndex]: selectedKeys[0] })
    setSearchedColumn({ ...searchedColumn, [dataIndex]: true })
  }

  const editSite = (record) => {
    setEditSiteData(record)
    setModalVisible(true)
  }

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      defaultSortOrder: 'ascend',
      filteredValue: filteredInfo?.name || null,
      sorter: ({ name: a }, { name: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'name',
        label: 'Sites',
      }),
    },
    {
      dataIndex: 'client_name',
      key: 'client_name',
      title: 'Organisation',
      filteredValue: filteredInfo?.client_name || null,
      sorter: ({ client_name: a }, { client_name: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'client_name',
        label: 'Organisations',
      }),
    },
    {
      dataIndex: 'address_line1',
      key: 'address_line1',
      title: 'Address Line 1',
      filteredValue: filteredInfo?.address_line1 || null,
      sorter: ({ address_line1: a }, { address_line1: b }) =>
        a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'address_line1',
        label: 'Address Line 1',
      }),
    },
    {
      dataIndex: 'address_line2',
      key: 'address_line2',
      title: 'Address Line 2',
      filteredValue: filteredInfo?.address_line2 || null,
      sorter: ({ address_line2: a }, { address_line2: b }) =>
        a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'address_line2',
        label: 'Address Line 2',
      }),
    },
    {
      dataIndex: 'city',
      key: 'city',
      title: 'City',
      filteredValue: filteredInfo?.city || null,
      sorter: ({ city: a }, { city: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'city',
        label: 'City',
      }),
    },
    {
      dataIndex: 'county',
      key: 'county',
      title: 'County',
      filteredValue: filteredInfo?.county || null,
      sorter: ({ county: a }, { county: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'county',
        label: 'County',
      }),
    },
    {
      dataIndex: 'post_code',
      key: 'post_code',
      title: 'Postcode',
      filteredValue: filteredInfo?.post_code || null,
      sorter: ({ post_code: a }, { post_code: b }) => a.localeCompare(b),
      ...getColumnSearchProps({
        dataIndex: 'post_code',
        label: 'Postcode',
      }),
    },
    {
      key: 'actions',
      render: (_, record) => {
        if (siteState.is.deleting && siteState.is.deletingId === record.id) {
          return <Spin tip="Deleting..." spinning />
        } else {
          return (
            <Popover
              content={
                <Menu>
                  <Menu.Item>
                    <Link onClick={() => editSite(record)}>Edit</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Popconfirm
                      title="Are you sure you want to delete this site?"
                      onConfirm={() =>
                        dispatch(siteEffects.deleteSite(record.id))
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Text type="danger">Delete</Text>
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="primary">Actions</Button>
            </Popover>
          )
        }
      },
    },
  ]

  return (
    <>
      <ListPageHeader
        title="Sites"
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
        columns={columns}
        filteredInfo={filteredInfo}
        lastUpdated={siteState.lastUpdated}
        onCreate={() => setModalVisible(true)}
        onRefresh={() => dispatch(siteEffects.fetchSites())}
        refreshButtonDisabled={siteState.is.fetching}
      />
      <Row justify="center">
        <Col span={24}>
          <Spin
            size="large"
            spinning={siteState.is.fetching}
            tip="Loading Sites..."
          >
            <Table
              columns={columns}
              dataSource={siteState.list.map((s) => ({
                ...s,
                key: s.id,
              }))}
              onChange={(_, filters) => setFilteredInfo(filters)}
              pagination={{ pageSize: 100 }}
            ></Table>
          </Spin>
        </Col>
      </Row>
      <SiteForm
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        editSiteData={editSiteData}
        setEditSiteData={setEditSiteData}
      />
    </>
  )
}
