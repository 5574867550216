import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  notification,
} from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import {
  actions as contactActions,
  effects as contactEffects,
  selectors as contactSelectors,
} from '../store/modules/contact'

export default ({
  editContactData,
  setEditContactData,
  modalVisible,
  setModalVisible,
}) => {
  const contactState = useSelector(contactSelectors.state)

  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const closeModal = () => setModalVisible(false)

  useEffect(() => {
    if (editContactData) {
      form.setFieldsValue(editContactData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContactData])

  useEffect(() => {
    if (contactState.success.creating || contactState.success.saving) {
      notification.success({
        message: `Contact ${editContactData ? 'Saved' : 'Created'}`,
      })
      dispatch(
        contactActions[
          `resetSuccess${editContactData ? 'Saving' : 'Creating'}`
        ]()
      )
      closeModal()
      dispatch(contactEffects.fetchContacts())
    } else if (contactState.error.creating || contactState.error.saving) {
      notification.error({
        message: `Could not ${editContactData ? 'save' : 'create'} Contact`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactState.success.creating,
    contactState.success.saving,
    contactState.error.creating,
    contactState.error.saving,
  ])

  const onFinish = (data) => {
    if (editContactData) {
      dispatch(contactEffects.saveContact({ id: editContactData.id, data }))
    } else {
      dispatch(contactEffects.createContact(data))
    }
  }

  const getFormItemHelp = (name) => {
    if (contactState.error.creating?.errors) {
      return contactState.error.creating.errors[name]
    } else if (contactState.error.saving?.errors) {
      return contactState.error.saving.errors[name]
    }
  }

  const getFormItemValidateStatus = (name) => {
    if (
      (contactState.error.creating?.errors &&
        contactState.error.creating?.errors[name]) ||
      (contactState.error.saving?.errors &&
        contactState.error.saving?.errors[name])
    ) {
      return 'error'
    }
  }

  return (
    <Modal
      afterClose={() => {
        form.resetFields()
        dispatch(
          contactActions[
            `resetError${editContactData ? 'Saving' : 'Creating'}`
          ]()
        )
        if (editContactData) {
          setEditContactData(null)
        }
      }}
      footer={null}
      onCancel={closeModal}
      title={`${editContactData ? 'Edit' : 'Create a New'} Contact`}
      visible={modalVisible}
    >
      <Spin
        size="large"
        spinning={contactState.is.creating || contactState.is.saving}
        tip={`${editContactData ? 'Saving' : 'Creating'} Contact...`}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Name is required' }]}
                help={getFormItemHelp('name')}
                validateStatus={getFormItemValidateStatus('name')}
              >
                <Input tabIndex={1} />
              </Form.Item>
              <Form.Item
                label="Company"
                name="company"
                rules={[{ required: true, message: 'Company is required' }]}
                help={getFormItemHelp('company')}
                validateStatus={getFormItemValidateStatus('company')}
              >
                <Input tabIndex={3} />
              </Form.Item>
              <Form.Item
                label="Tel Nos"
                name="telnos"
                rules={[{ required: true, message: 'Tel Nos is required' }]}
                help={getFormItemHelp('telnos')}
                validateStatus={getFormItemValidateStatus('telnos')}
              >
                <Input tabIndex={5} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Title is required' }]}
                help={getFormItemHelp('title')}
                validateStatus={getFormItemValidateStatus('title')}
              >
                <Input tabIndex={2} />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: 'Address is required' }]}
                help={getFormItemHelp('address')}
                validateStatus={getFormItemValidateStatus('address')}
              >
                <Input tabIndex={4} />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                help={getFormItemHelp('email')}
                validateStatus={getFormItemValidateStatus('email')}
              >
                <Input tabIndex={6} type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={24}>
            <Col span={15}>
              {((contactState.error.creating?.message &&
                !contactState.error.creating?.exception) ||
                (contactState.error.saving?.message &&
                  !contactState.error.saving?.exception)) && (
                <Alert
                  message={
                    contactState.error.creating?.message ||
                    contactState.error.saving?.message
                  }
                  type="error"
                  showIcon
                />
              )}
            </Col>
            <Col span={9} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                htmlType="submit"
              >
                {editContactData ? 'Save' : 'Create'} Contact
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
