import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { effects as authEffects } from './auth'
import { authify } from '../../utils'

const namespace = 'contact'

export const effects = {
  createContact: createAsyncThunk('createContact', async (data, thunkAPI) =>
    authify(thunkAPI, 'post', '/contacts', data)
  ),
  deleteContact: createAsyncThunk('deleteContact', async (id, thunkAPI) =>
    authify(thunkAPI, 'delete', `/contacts/${id}`)
  ),
  fetchContacts: createAsyncThunk('fetchContacts', async (_, thunkAPI) =>
    authify(thunkAPI, 'get', '/contacts')
  ),
  saveContact: createAsyncThunk('saveContact', async ({ id, data }, thunkAPI) =>
    authify(thunkAPI, 'patch', `/contacts/${id}`, data)
  ),
}

const initialState = {
  error: {
    creating: null,
    deleting: null,
    fetching: null,
    saving: null,
  },
  is: {
    creating: false,
    deleting: false,
    deletingId: null,
    fetching: false,
    saving: false,
  },
  success: {
    creating: false,
    deleting: false,
    fetching: false,
    saving: false,
  },
  lastUpdated: null,
  list: [],
}

export const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset: () => initialState,
    resetErrorCreating: (state) => {
      state.error.creating = initialState.error.creating
    },
    resetErrorDeleting: (state) => {
      state.error.deleting = initialState.error.deleting
    },
    resetErrorFetching: (state) => {
      state.error.fetching = initialState.error.fetching
    },
    resetErrorSaving: (state) => {
      state.error.saving = initialState.error.saving
    },
    resetSuccessCreating: (state) => {
      state.success.creating = initialState.success.creating
    },
    resetSuccessDeleting: (state) => {
      state.success.deleting = initialState.success.deleting
    },
    resetSuccessFetching: (state) => {
      state.success.fetching = initialState.success.fetching
    },
    resetSuccessSaving: (state) => {
      state.success.saving = initialState.success.saving
    },
  },
  extraReducers: {
    [effects.createContact.pending]: (state) => {
      state.is.creating = true
      state.error.creating = initialState.error.creating
      state.success.creating = initialState.error.creating
    },
    [effects.createContact.rejected]: (state, { payload }) => {
      state.is.creating = initialState.is.creating
      state.error.creating = payload
      state.success.creating = initialState.success.creating
    },
    [effects.createContact.fulfilled]: (state) => {
      state.is.creating = initialState.is.creating
      state.error.creating = initialState.error.creating
      state.success.creating = true
    },
    [effects.deleteContact.pending]: (state, { meta: { arg } }) => {
      state.is.deleting = true
      state.is.deletingId = arg
      state.error.deleting = initialState.error.deleting
      state.success.deleting = initialState.error.deleting
    },
    [effects.deleteContact.rejected]: (state, { payload }) => {
      state.is.deleting = initialState.is.deleting
      state.is.deletingId = initialState.is.deletingId
      state.error.deleting = payload
      state.success.deleting = initialState.success.deleting
    },
    [effects.deleteContact.fulfilled]: (state) => {
      state.is.deleting = initialState.is.deleting
      state.is.deletingId = initialState.is.deletingId
      state.error.deleting = initialState.error.deleting
      state.success.deleting = true
    },
    [effects.saveContact.pending]: (state) => {
      state.is.saving = true
      state.error.saving = initialState.error.saving
      state.success.saving = initialState.error.saving
    },
    [effects.saveContact.rejected]: (state, { payload }) => {
      state.is.saving = initialState.is.saving
      state.error.saving = payload
      state.success.saving = initialState.success.saving
    },
    [effects.saveContact.fulfilled]: (state) => {
      state.is.saving = initialState.is.saving
      state.error.saving = initialState.error.saving
      state.success.saving = true
    },
    [effects.fetchContacts.pending]: (state) => {
      state.is.fetching = true
      state.error.fetching = initialState.error.fetching
      state.success.fetching = initialState.error.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchContacts.rejected]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = payload
      state.success.fetching = initialState.success.fetching
      state.lastUpdated = initialState.lastUpdated
    },
    [effects.fetchContacts.fulfilled]: (state, { payload }) => {
      state.is.fetching = initialState.is.fetching
      state.error.fetching = initialState.error.fetching
      state.success.fetching = true
      state.list = payload || []
      state.lastUpdated = moment().format('X')
    },
    [authEffects.logout.fulfilled]: () => initialState,
  },
})

export const selectors = {
  state: (state) => state[namespace],
}

export default reducer
