import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Spin,
  Switch,
  Typography,
  Upload,
} from 'antd'
import {
  CloudUploadOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  actions as organisationActions,
  effects as organisationEffects,
  selectors as organisationSelectors,
} from '../store/modules/organisation'
import api from '../api'
import './index.scss'

const { Title } = Typography

export default ({
  editOrganisationData,
  setEditOrganisationData,
  modalVisible,
  setModalVisible,
}) => {
  const organisationState = useSelector(organisationSelectors.state)

  const [base64Logo, setBase64Logo] = useState(null)
  const [companyNumberOptional, setCompanyNumberOptional] = useState(false)
  const [fetchingCHData, setFetchingCHData] = useState(false)
  const [logoFileList, setLogoFileList] = useState([])
  const [logoPreviewVisible, setLogoPreviewVisible] = useState(false)

  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const closeModal = () => setModalVisible(false)

  useEffect(() => {
    if (editOrganisationData) {
      form.setFieldsValue(editOrganisationData)
      setCompanyNumberOptional(editOrganisationData.is_gov)
      if (editOrganisationData.logo) {
        setBase64Logo(editOrganisationData.logo)
        setLogoFileList([
          { uid: '1', status: 'done', url: editOrganisationData.logo },
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrganisationData])

  useEffect(() => {
    if (
      organisationState.success.creating ||
      organisationState.success.saving
    ) {
      notification.success({
        message: `Organisation ${editOrganisationData ? 'Saved' : 'Created'}`,
      })
      dispatch(
        organisationActions[
          `resetSuccess${editOrganisationData ? 'Saving' : 'Creating'}`
        ]()
      )
      closeModal()
      dispatch(organisationEffects.fetchOrganisations())
    } else if (
      organisationState.error.creating ||
      organisationState.error.saving
    ) {
      notification.error({
        message: `Could not ${
          editOrganisationData ? 'save' : 'create'
        } Organisation`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organisationState.success.creating,
    organisationState.success.saving,
    organisationState.error.creating,
    organisationState.error.saving,
  ])

  const onFinish = (formData) => {
    const data = {
      ...formData,
      is_gov: companyNumberOptional,
      logo: base64Logo,
    }
    if (editOrganisationData) {
      dispatch(
        organisationEffects.saveOrganisation({
          id: editOrganisationData.id,
          data,
        })
      )
    } else {
      dispatch(organisationEffects.createOrganisation(data))
    }
  }

  const onIsGovChange = (isGov) => {
    setCompanyNumberOptional(isGov)
  }

  const getFormItemHelp = (name) => {
    if (organisationState.error.creating?.errors) {
      return organisationState.error.creating.errors[name]
    } else if (organisationState.error.saving?.errors) {
      return organisationState.error.saving.errors[name]
    }
  }

  const getFormItemValidateStatus = (name) => {
    if (
      (organisationState.error.creating?.errors &&
        organisationState.error.creating.errors[name]) ||
      (organisationState.error.saving?.errors &&
        organisationState.error.saving.errors[name])
    ) {
      return 'error'
    }
  }
  return (
    <Modal
      afterClose={() => {
        form.resetFields()
        dispatch(
          organisationActions[
            `resetError${editOrganisationData ? 'Saving' : 'Creating'}`
          ]()
        )
        setBase64Logo(null)
        setFetchingCHData(false)
        setLogoFileList([])
        setEditOrganisationData(null)
      }}
      className="organisation-form-modal"
      footer={null}
      onCancel={closeModal}
      visible={modalVisible}
    >
      <Spin
        size="large"
        spinning={
          organisationState.is[editOrganisationData ? 'saving' : 'creating']
        }
        tip={`${editOrganisationData ? 'Saving' : 'Creating'} Organisation...`}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Title level={3}>
            {editOrganisationData ? 'Edit' : 'Create a New'} Organisation
          </Title>
          <Row justify="space-between">
            <Col>
              <Title level={4}>ORGANISATION SETUP</Title>
            </Col>
            <Col>
              <Row justify="end" gutter={16}>
                <Col>Is Government Body</Col>
                <Col>
                  <Switch
                    checked={companyNumberOptional}
                    onChange={onIsGovChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="company_number"
                label="Company Number"
                min={4}
                max={64}
                rules={[
                  {
                    required: !companyNumberOptional,
                    message: 'Company Number is required',
                  },
                ]}
                help={getFormItemHelp('company_number')}
                validateStatus={getFormItemValidateStatus('company_number')}
              >
                <Input.Search
                  enterButton="Lookup"
                  loading={fetchingCHData}
                  onSearch={async (value) => {
                    if (value) {
                      form.setFieldsValue({
                        name: '',
                        address_line1: '',
                        address_line2: '',
                        city: '',
                        county: '',
                        post_code: '',
                      })
                      setFetchingCHData(true)
                      try {
                        const data = await api('companieshouse', value)
                        form.setFieldsValue({
                          name: data.company_name,
                          address_line1:
                            data.registered_office_address.address_line_1,
                          address_line2:
                            data.registered_office_address.address_line_2,
                          city: data.registered_office_address.locality,
                          county: data.registered_office_address.region,
                          post_code: data.registered_office_address.postal_code,
                        })
                      } catch (error) {
                        message.error('Could not find company details')
                      }
                      setFetchingCHData(false)
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Company Name"
                min={4}
                max={50}
                rules={[
                  { required: true, message: 'Company Name is required' },
                ]}
                help={getFormItemHelp('name')}
                validateStatus={getFormItemValidateStatus('name')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={18}>
              <Form.Item
                name="description"
                label="Description"
                extra="Max 64 Characters"
                min={4}
                max={64}
                help={getFormItemHelp('description')}
                validateStatus={getFormItemValidateStatus('description')}
              >
                <Input.TextArea maxLength={200} rows={4} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Logo"
                help={getFormItemHelp('logo')}
                validateStatus={getFormItemValidateStatus('logo')}
              >
                <Upload
                  accept="image/*"
                  beforeUpload={(file) => {
                    const isLt10M = file.size / 1024 / 1024 < 10
                    if (!isLt10M) {
                      message.error('Image must be smaller than 10MB!')
                    }
                    return isLt10M
                  }}
                  customRequest={({ file }) => {
                    const reader = new FileReader()
                    reader.addEventListener('load', () => {
                      setBase64Logo(reader.result)
                      setLogoFileList([
                        { uid: '1', status: 'done', url: reader.result },
                      ])
                    })
                    reader.readAsDataURL(file)
                  }}
                  fileList={logoFileList}
                  listType="picture-card"
                  onPreview={() => setLogoPreviewVisible(true)}
                  showUploadList={{
                    removeIcon: (
                      <Popconfirm
                        onConfirm={() => {
                          setBase64Logo(null)
                          setLogoFileList([])
                        }}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    ),
                  }}
                >
                  {!logoFileList.length && (
                    <>
                      <UploadOutlined /> Upload
                    </>
                  )}
                </Upload>
              </Form.Item>
              <Modal
                visible={logoPreviewVisible}
                title="Logo"
                footer={null}
                onCancel={() => setLogoPreviewVisible(false)}
              >
                <img alt="logo" style={{ width: '100%' }} src={base64Logo} />
              </Modal>
            </Col>
          </Row>
          <Title level={4}>ADDRESS DETAILS</Title>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="address_line1"
                label="Address Line 1"
                min={4}
                max={64}
                help={getFormItemHelp('address_line1')}
                validateStatus={getFormItemValidateStatus('address_line1')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address_line2"
                label="Address Line 2"
                min={4}
                max={64}
                help={getFormItemHelp('address_line2')}
                validateStatus={getFormItemValidateStatus('address_line2')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="city"
                label="City"
                min={4}
                max={64}
                help={getFormItemHelp('city')}
                validateStatus={getFormItemValidateStatus('city')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="county"
                label="County"
                min={4}
                max={64}
                help={getFormItemHelp('county')}
                validateStatus={getFormItemValidateStatus('county')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row align="bottom" gutter={24}>
            <Col span={12}>
              <Form.Item
                name="post_code"
                label="Postcode"
                min={4}
                max={64}
                help={getFormItemHelp('post_code')}
                validateStatus={getFormItemValidateStatus('post_code')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={24}>
            <Col span={13}>
              {((organisationState.error.creating?.message &&
                !organisationState.error.creating?.exception) ||
                (organisationState.error.saving?.message &&
                  !organisationState.error.saving?.exception)) && (
                <Alert
                  message={
                    organisationState.error.creating?.message ||
                    organisationState.error.saving?.message
                  }
                  type="error"
                  showIcon
                />
              )}
            </Col>
            <Col span={11} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                htmlType="submit"
              >
                {editOrganisationData ? 'Save' : 'Create'} Organisation
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
